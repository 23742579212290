import { GiftCardEvent } from './giftcardEvent';

export class GiftCard {

    public id: number;
    public string: string;
    public amount: number;
    public amount_left: number;
    public expire: string;
    public events: GiftCardEvent[] = [];

}

