import {Component, OnInit} from '@angular/core';
import { environment } from '../environments/environment';
import {TranslateService} from "@ngx-translate/core";
import { DataService } from './Services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent {
  title = 'tsnet.org';
  public env = environment;

  constructor(private translate: TranslateService, private data: DataService) {
    
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit(): void {
    console.log('Selected Language:',this.data.currentUser.language)
   }


}
