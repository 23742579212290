import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { environment } from 'src/environments/environment';
import { App } from '../../../../../shared/models/app';
import { Chat } from '../../../../../shared/models/chat';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {


  baseUrl:string = environment.apiUrl;
  heading = 'Administrera menyn';
  subheading = 'Här ändrar du menyn';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public app: App;
  public chats: Chat[];
  public chat: Chat;
  public messages: Chat[];
  public message: string;

  private sending: boolean;
  

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
  
      let app_id = +this.route.snapshot.paramMap.get("app_id");

      let id = +this.route.snapshot.paramMap.get("id");

      this.data.currentUser.apps.forEach(item => {
        if (item.id == app_id)
        {
          this.app = item;
        }
      })

      this.api.getActiveChats(this.app.id).subscribe((data) => {
        this.chats = data;

        this.chats.forEach(item => {
          if (item.device_id == id)
          {
            this.chat = item;

            this.api.getChats(this.app.id, this.chat.device_id).subscribe(data => {
              this.messages = data;
            })

          }
        })

      })



      
    setInterval(() => {
      
      this.data.interval = this.api.getChats(this.app.id, this.chat.device_id).subscribe(data => {
        this.messages = data;
      })

    }, 10000);

    router.events.subscribe(
      (event) => {
          if ( event instanceof NavigationEnd ) {
            clearInterval(this.data.interval);
          }
      });

  }

  ngOnInit() {

  }




  device_chat()
  {

    this.api.getChats(this.app.id, this.chat.device_id).subscribe(data => {
      this.messages = data;
    })

  }

  send(){

    let postObject = {
      text: this.message,
      did: this.chat.device_id,
      status: 1,
      user_id: this.data.currentUser.id, 
      app_id: this.app.id

    }

    if (!this.sending)
    {
      this.sending = true;
      this.api.postChat(postObject).subscribe(data => {
        this.messages = data;
        this.message = "";
        this.sending = false;
      })

    }



  }





}