import { Component, OnInit } from '@angular/core';
import { User } from '../../../../../shared/models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { Response } from '../../../../../shared/models/response';
import { App } from '../../../../../shared/models/app';
import { Customer } from '../../../../../shared/models/customer';
import { DataService } from 'src/app/Services/data.service';
import { Byline } from '../../../../../shared/models/byline';
import { Email } from '../../../../../shared/models/email';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-admin-user-edit',
  templateUrl: './admin-user-edit.component.html',
  styleUrls: ['./admin-user-edit.component.sass']
})
export class AdminUserEditComponent implements OnInit {

  baseUrl:string = environment.apiUrl;

  heading = 'Skapa användare';
  subheading = 'Här skapar du en ny användare';
  icon = 'pe-7s-users icon-gradient bg-arielle-smile';

  public roles = ['Användare'];
  public languages = ['sv', 'en', 'ar', 'ne', 'fr'];

  public user: User = new User();
  public apps: App[] = [];
  public customers: Customer[] = [];
  public bylines: Byline[] = [];
  public emails: Email[] = [];

  constructor(private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private data: DataService) {

    let id = +this.route.snapshot.paramMap.get("id");

    this.user.role = "Administratör";

    if (this.data.currentUser.role == "SuperAdmin")
      {
        this.roles = ['School-Administrator', 'Administratör','SuperAdmin'];
      }

    if (id > 0)
    {
        this.api.getUser(id).subscribe((data:User) => {
          this.user = data;
          this.user.isJournalist = +this.user.isJournalist;
        })
    }

    this.api.getApps().subscribe((data:App[]) => {
      this.apps = data;
    });

    this.api.getCustomers().subscribe((data: Customer[]) => {
      this.customers = data;
    })

    this.api.getBylines().subscribe((data: Byline[]) => {
      this.bylines = data;
    })

    this.api.getEmails(id).subscribe((data: Email[]) => {
      this.emails = data;
    })

   }

  ngOnInit() {
  }

  save() {

    this.api.setUser(this.user).subscribe((data:Response) => {

      if (data.code == 200)
      {
        this.router.navigateByUrl('/admin/allusers')
      }
    })

  }

  public send_email(type)
  {
    if (type == "restore")
    {
      this.api.send_restore(this.user.email).subscribe((data:Response) => {
        if (data.message == "SENT")
        {
          this.api.showSuccess("Skickat", "Återställningsmailet skickades");
        }
      })
    }
    else if (type == "welcome")
    {
      this.api.send_welcome(this.user.id).subscribe((data:Response) => {
        if (data.message == "SENT")
        {
          this.api.showSuccess("Skickat", "Välkomstsmailet skickades");
        }
      })
    }
    else if (type == "pren")
    {
      this.api.send_pren(this.user.id).subscribe((data:Response) => {
        if (data.message == "SENT")
        {
          this.api.showSuccess("Skickat", "Välkomstsmailet skickades");
        }
      })
    }


  }

  onUploadChange(evt: any) {
    const file = evt.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoaded(e) {
    let img_str = btoa(e.target.result);
    this.api.setUserAvatar(this.user.id, img_str).subscribe((data: Response) => {
      this.api.showSuccess("Uppladdad", "Bilden sparades");
      this.user.avatar = data.message;
    }, error => this.api.showError(error))
  }

  public delete()
  {
    this.api.delUser(this.user.id).subscribe((data:Response) => {

        this.router.navigateByUrl('/admin/allusers')
    })

  }

  public opencustomer(customer:Customer)
  {
    this.router.navigateByUrl('/admin/customer/' + customer.id);
  }

}
