import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/Services/data.service';
import { HttpClient } from '@angular/common/http';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { AppUser } from '../../../../../shared/models/appuser';
import { DataTablesResponse } from '../../../../../shared/models/datatabelsResponse';
import { environment } from '../../../environments/environment';
import { App } from '../../../../../shared/models/app';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-admin-app-users',
  templateUrl: './admin-app-users.component.html',
  styleUrls: ['./admin-app-users.component.scss']
})
export class AdminAppUsersComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = `${this.translateMe("global.allSubscribers")}`;
  // subheading = 'Här redigerar du prenumeranterna i apparna.';
  icon = 'pe-7s-users icon-gradient bg-arielle-smile';

  public users: AppUser[] = [];
  public apps: App[] = [];
  public app: App;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  constructor(
    private data: DataService, 
    private http: HttpClient,
    private route: ActivatedRoute, 
    private router: Router,
    private translate: TranslateService
  ) {
    this.app = this.data.currentUser.apps[0];
    console.log(this.data.currentUser.default_app)

    this.apps = this.data.currentUser.apps;
  }

  ngOnInit(): void {
    this.app = this.data.currentUser.default_app;
    // console.log(this.data.currentUser.default_app)
    this.loadUserTable();
  }

  translateMe(key:string) {
    let word = ""
    this.translate.get(key, { value: ""}).subscribe((res: string)=> {word = res})
    return word
  }

  loadUserTable(): void {
    const that = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      language: {
        "emptyTable":     "Tabellen innehåller ingen data",
        "info":           "Shows row _START_ - _END_ of _TOTAL_ rows",
        "infoEmpty":      "Shows 0 out of 0 rows",
        "infoFiltered":   "(av totalt _MAX_ rader)",
        "infoPostFix":    "",
        "thousands":      ",",
        "lengthMenu":     "Show _MENU_ rows per page",
        "loadingRecords": "Loading...",
        "processing":     "Laddar...",
        "search":         "Search:",
        "zeroRecords":    "Inget innehåll hittat",
        "paginate": {
          "first":      "First",
          "last":       "Last",
          "next":       "Next",
          "previous":   "Previous"
        },
        "aria": {
            "sortAscending":  ": activate to sort column ascending",
            "sortDescending": ": activate to sort column descending"
        }
    },
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post<DataTablesResponse>(
            this.baseUrl + '/api/appusers?appid=' + this.app.id,
            dataTablesParameters, {}
          ).subscribe(resp => {

            that.users = resp.data;
            // console.log(typeof(that.users[0].apps))

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [{ data: 'id' }, { data: 'name' }, { data: 'email' }, { data: 'role' }]
    };

  }

  ngOnDestroy(): void {
  }

  onChange()
  {

    this.data.currentUser.default_app = this.app;
    this.loadUserTable();

    // this.api.setUser(this.data.currentUser).subscribe(() => {

    this.router.navigateByUrl('/admin/appuser/0', { skipLocationChange: true }).then(
      x => {
        this.router.navigateByUrl('/admin/allappusers', { skipLocationChange: true });
      }
    )
      // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      //   dtInstance.ajax.reload();
      // });


    // });


  }



}
