import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

/*
  För att använda, skriv t ex:
    <app-tooltip
      content="Test"
    ></app-tooltip>
*/

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TooltipComponent implements OnInit {
  @Input() content: string;
  @Input() icon: string;

  constructor() { }
  
  ngOnInit() {
    if (!this.content) {
      this.content = "En informationsbubbla."
    } 
    if (!this.icon) {
      this.icon = "help_outline"
    }
  }
}
