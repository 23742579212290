import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../../../../shared/models/user';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  heading = 'Ändra lösenord';
  subheading = 'Här ändrar du ditt lösenord.';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public password: String;
  public password2: String;
  private user: User;

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {}



  ngOnInit() {

    this.api.getUser(this.data.currentUser.id).subscribe((data:User) => {
      this.user = data;
    })

  }



  save()
  {

    if (this.password != "" && this.password == this.password2)    {
      this.user.password = this.password;
      this.api.setUser(this.user).subscribe(data => {
        this.api.showSuccess("Sparad", "Lösenordet sparades");
      }, error => this.api.showError(error));
    }
  }

}
