import { Customer } from './customer';
import { App } from './app';
import { Template } from './template';

export class SportKey {

    public id: number;
    public everysportKey: String;
    public customer: Customer;
    public app: App;
    public template_full: Template;
    public template_feed: Template;
    
}

