import { Component, OnInit } from '@angular/core';
import { Router, Route, ActivatedRoute } from '@angular/router';

import { ApiService } from 'src/app/Services/api.service';
import { examTest } from '../../../../../shared/models/examTest';

@Component({
  selector: 'app-school-test',
  templateUrl: './school-test.component.html',
  styleUrls: ['./school-test.component.scss']
})
export class SchoolTestComponent implements OnInit {
  public id: number;
  public pageData: examTest[] = [];
  // public question: ExamTest;
  public editId: number;

  icon = 'pe-7s-note icon-gradient bg-arielle-smile';

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private api: ApiService,
  ) { 
    this.id = +this.route.snapshot.paramMap.get("id");
    if (this.id >= 1) {
      
      console.log("Test ID", this.id)
      
    } else {
      // Get new test ID
      console.log("Get new test ID")
    }
  }

  ngOnInit(): void {

    this.api.getQuestions(this.id).subscribe((data:any) => {
      this.pageData = data.data;
      console.log(this.pageData)
    })
  }

  add_row() {
    console.log('add row')
    let new_id = 0;
    if (this.pageData && this.pageData.length > 0) {
      this.pageData.forEach(item => {
        if (item.id > new_id)
        {
          new_id = item.id;
        }
      })
    }

    let newObject = new examTest()
    // newObject.temp_id =new_id + 1;
    newObject.test_id = this.id;
    newObject.question = '';
    
    if (!this.pageData) {
      console.log('set')
      this.pageData = [newObject];
    } else {
      console.log('set')
      this.pageData.push(newObject);
    }
   
    this.editId = new_id;
  }

  save() {
    console.log(this.pageData)
    this.api.setQuestions(this.pageData).subscribe((data:any) => {
      this.api.showSuccess('Saved','Test Saved');
      console.log(data)
    })

  }

  remove(i)
  {
    const question = this.pageData[i];
    // console.log(question)
    this.api.deleteQuestion(question).subscribe((data:any) => {
      this.api.showSuccess('Deleted','Question Deleted');
      console.log(data)
    })
    this.pageData.splice(i, 1);
    this.editId = 0;
  }

  
}
