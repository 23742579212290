import { Component, OnInit } from '@angular/core';
import { Customer } from '../../../../../shared/models/customer';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';
import { App } from '../../../../../shared/models/app';

@Component({
  selector: 'app-sales-contracts',
  templateUrl: './sales-contracts.component.html',
  styleUrls: ['./sales-contracts.component.scss']
})
export class SalesContractsComponent implements OnInit {

  heading = 'Avtal';
  subheading = 'Hantera kunder';
  icon = 'pe-7s-note icon-gradient bg-arielle-smile';

  public customers: Customer[];
  public apps: App[];
  public selectedApps: App[] = [];
  public total = 0;


  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {

      this.apps = this.data.currentUser.apps;
      this.selectedApps = this.data.currentUser.selectedApps;

      this.app_change();


  }

  ngOnInit() {

  }

  app_change()
  {

    this.data.currentUser.selectedApps = this.selectedApps;

    this.api.getCustomersByContract(this.selectedApps).subscribe((data: Customer[]) => {
      this.customers = data;

      this.total = 0;

      data.forEach(customer => {

        let number_active_apps = 0;
        customer.apps.forEach(x=>{
          this.selectedApps.forEach(y=>{
            if (x.id == y.id)
            {
              number_active_apps++;
            }
          })
        })

        let number_apps = customer.apps.length;

        let value = customer.contracts_value / number_apps * number_active_apps;

        this.total += value;

      })

    })
  }

  open(customer_id: number, contract_id)
  {
    this.api.getCustomer(customer_id).subscribe((data: Customer) => {
      this.data.currentUser.customer = data;
      this.api.setUser(this.data.currentUser).subscribe((data: Response) => {
        sessionStorage.setItem("url", "/contract/" + contract_id);
        this.router.navigateByUrl('/login');
      })

    })


  }



}
