import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from 'src/app/Services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { App } from '../../../../../shared/models/app';

@Component({
  selector: 'app-video-upload',
  templateUrl: './video-upload.component.html',
  styleUrls: ['./video-upload.component.scss']
})
export class VideoUploadComponent implements OnInit {

  heading = 'Upload Audio';
  subheading = '';
  icon = 'pe-7s-volume2 icon-gradient bg-arielle-smile';

  public statdata: any;
  public loaded:boolean = false;
  public progress = { loaded : 0 , total : 0 };
  public apps: App[];
  public selectedApp: App;

  constructor(public data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
      this.apps = this.data.currentUser.apps;
      this.selectedApp = this.data.currentUser.default_app;
  }

  @ViewChild('selectfile') el:ElementRef;   //in html we make variable of selectfile



  ngOnInit() {

  }

  public uploadFile(file) {
    var filedata = this.el.nativeElement.files[0];

    this.api.uploadAudio(filedata, this.selectedApp.id).subscribe((data:any) => {

      if(data.type == 1 && data.loaded && data.total){
        this.progress.loaded = data.loaded;
        this.progress.total = data.total;
      }
      else if(data.body){
        console.log(data.body);
      }

    });

  }
  public change() {
    this.data.currentUser.default_app = this.selectedApp;
  }

  public newUpload(){
    this.progress.loaded = 0;
    this.progress.total = 0;
  }

}
