import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Customer } from '../../../../../shared/models/customer';
import { Media } from '../../../../../shared/models/media';
import { Response } from '../../../../../shared/models/response';
import { User } from '../../../../../shared/models/user';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-christmas-signage',
  templateUrl: './christmas-signage.component.html',
  styleUrls: ['./christmas-signage.component.scss']
})
export class ChristmasSignageComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Julskyltning';
  subheading = 'Välj Redigera för att ändra julskyltning';
  icon = 'pe-7s-photo icon-gradient bg-malibu-beach';

  public customer: Customer = new Customer();

  constructor(private data: DataService, private api: ApiService) {

  }

  public mediaSearchResult: Media[] = [];
  public preview_str: String;
  public s: String;

  public dropConfig: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    url: this.baseUrl + '/api/media/upload?profile=true&customer_id=' + this.data.currentUser.customer.id
  };
  @ViewChild(DropzoneComponent) DropZone?: DropzoneComponent;

  ngOnInit(): void {
    this.api.getCustomer(this.data.currentUser.customer.id).subscribe((data: Customer) => {
      this.customer = data;
    } )

    this.api.getMedias(this.data.currentUser.customer.id).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })
  }

  check_media(m: Media) {
    if (this.customer.christmas_images)
    {
      return !this.customer.christmas_images.find(x => x["id"] === m.id);
    }
    return true;
  }

  img_search() {
    this.api.searchMedias(this.data.currentUser.customer.id, this.s).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })
  }

  addimage(m: Media) {
    this.customer.christmas_images.push(m);
  }

  removeImage(index) {
    this.customer.christmas_images.splice(index, 1);
  }

  public onUploadInit(args: any): void {
    console.log('onUploadInit:', args);
  }

  public onUploadError(args: any): void {
    console.log('onUploadError:', args);
  }

  public onUploadSuccess(args: any): void {
    let id:number = +args[1];

    this.api.getMedia(id).subscribe((data: Media) => {
      this.customer.christmas_images.push(data);
      this.DropZone.directiveRef.reset();
    })
  }

  save() {
    this.api.setCustomer(this.customer).subscribe((data:Response)=> {
        this.customer.id = data.id;
        this.api.showSuccess("Sparad", "Kunden sparades.");

        this.api.getUser(this.data.currentUser.id).subscribe((data: User) => {
          this.data.currentUser = data;
        });

    }, error => this.api.showError(error));
  }
}
