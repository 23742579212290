import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Response } from '../../../../../shared/models/response';
import { Today } from '../../../../../shared/models/today';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';

@Component({
  selector: 'app-admin-today-edit',
  templateUrl: './admin-today-edit.component.html',
  styleUrls: ['./admin-today-edit.component.scss']
})
export class AdminTodayEditComponent implements OnInit {
  heading = 'Skapa ny idag';
  subheading = 'Här skapar du en ny idag';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public today: Today = new Today();

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
    let id = +this.route.snapshot.paramMap.get("id");
    
    if (id != 0)
    {

      this.heading = 'Redigera Idag';
      this.subheading = 'Här redigerar du en idag';

      this.api.getToday(id).subscribe((data: Today) => {
        this.today = data;
      })
    }
    else
    {
      this.today.id = 0;
    }

  }

  ngOnInit() {
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '2rem',
    placeholder: 'Skriv texten här...',
    translate: 'sv',
};

 

  save()
  {
    this.api.setToday(this.today).subscribe((data:Response)=>{
      this.today.id = data.id;
      this.api.showSuccess("Sparad", "Idag sparades");
    }, error => this.api.showError(error));
  }

}
