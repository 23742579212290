import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { Chapter } from '../../../../../shared/models/chapter';
import { Response } from '../../../../../shared/models/response';
import { ToastrService } from 'ngx-toastr';
import { App } from '../../../../../shared/models/app';
import { DataService } from 'src/app/Services/data.service';

@Component({
  selector: 'app-school-course-edit',
  templateUrl: './school-course-edit.component.html',
  styleUrls: ['./school-course-edit.component.scss']
})
export class SchoolCourseEditComponent implements OnInit {
  heading = 'Edit Chapter';
  icon = 'pe-7s-photo icon-gradient bg-malibu-beach';
  
  public id: number = 0
  public chapter: Chapter;
  public apps: App[];
  public selectedApp: App;
  
  constructor( 
    private route: ActivatedRoute, 
    private router: Router,
    private api: ApiService,
    private toastr: ToastrService,
    private data: DataService,
    
  ) { 
    this.apps = this.data.currentUser.apps;
    this.selectedApp = this.data.currentUser.default_app;
    this.id = +this.route.snapshot.paramMap.get("id");
  }

  ngOnInit(): void {
    // console.log(this.id)
    this.loadChapter()
    // console.log(this.data.currentUser.default_app)
  }

  loadChapter() {
    if (this.id === 0 ) {
      this.chapter = new Chapter
      return
    }
    this.api.getChapter(this.id).subscribe((data:Chapter) => {
      this.chapter = data;
     
      this.selectedApp = this.apps.find(x => x.id === this.chapter.app_id)
    })
  }

  save() {
    // if (this.id === 0 ) {
    //   this.chapter.chapter_id = this.chapter
    // }
    this.chapter.app_id = this.selectedApp.id;
    // console.log(this.chapter)
    this.api.setChapter(this.chapter).subscribe((data:Response)=> {
      
      this.toastr.success("Chapter saved", "Saved", { progressBar: true, closeButton: true });
      this.router.navigate(['/courses/'])
    }, error => this.api.showError(error));
  }
  

  delete() {

    // console.log(this.chapter)
    this.api.deleteChapter(this.chapter).subscribe((data:Response)=> {
      
      this.toastr.success("Chapter deleted", "Deleted", { progressBar: true, closeButton: true });
      this.router.navigate(['/courses/'])
    }, error => this.api.showError(error));
  }

  change() {
    this.data.currentUser.default_app = this.selectedApp;
    console.log(this.selectedApp.id)
  }

}
