import { Component, OnInit } from '@angular/core';
import { Customer } from '../../../../../shared/models/customer';
import { App } from '../../../../../shared/models/app';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';
import { Section } from '../../../../../shared/models/section';
import { Template } from '../../../../../shared/models/template';
import { User } from '../../../../../shared/models/user';
import { environment } from '../../../environments/environment';
import { CustomerLog } from '../../../../../shared/models/customer_log';

@Component({
  selector: 'app-admin-customer-edit',
  templateUrl: './admin-customer-edit.component.html',
  styleUrls: ['./admin-customer-edit.component.scss']
})
export class AdminCustomerEditComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Skapa kund';
  subheading = 'Lägg till en ny kund (företag eller förening)';
  icon = 'pe-7s-id icon-gradient bg-malibu-beach';

  public customer: Customer = new Customer();
  public apps: App[] = [];
  public sections: Section[] = [];
  public templates: Template[] = [];
  public customers: Customer[] = [];
  public employees: User[] = [];
  public new_log: CustomerLog = new CustomerLog();

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
    let id = +this.route.snapshot.paramMap.get("id");

    this.api.getApps().subscribe((data: App[]) => {
      this.apps = data;
    })

    this.api.getSections().subscribe((data: Section[]) => {
      this.sections = data;
    })

    this.api.getCustomers().subscribe((data: Customer[]) => {
      this.customers = data;
    })

    this.api.getTemplates().subscribe((data: Template[]) => {
      this.templates = data;
    })

    this.api.getEmployees().subscribe((data: User[]) => {
      this.employees = data;
    })

    if (id != 0)
    {

      this.heading = 'Redigera kund';
      this.subheading = 'Här redigerar du en kund (företag eller förening)';

      this.api.getCustomer(id).subscribe((data: Customer) => {
        this.customer = data;
      }, error => this.api.showError(error))
    }
    else
    {
      this.customer.id = 0;
    }

  }

  ngOnInit() {
    this.apps = this.data.currentUser.customer.apps;

    this.new_log.time = new Date().toISOString();

  }



  save()
  {
    this.api.setCustomer(this.customer).subscribe((data:Response)=>{
        this.customer.id = data.id;
        this.api.showSuccess("Sparad", "Kunden sparades.");

        this.api.getUser(this.data.currentUser.id).subscribe((data: User) => {
          this.data.currentUser = data;
        });

    }, error => this.api.showError(error));



  }

  onUploadChange(evt: any) {
    const file = evt.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoaded(e) {
    let img_str = btoa(e.target.result);
    this.api.setCustomerLogo(this.customer.id, img_str).subscribe((data: Response) => {
      this.api.showSuccess("Uppladdad", "Loggan sparades");
    }, error => this.api.showError(error))
  }

  public delete()
  {
    if(confirm("Är du säker på att du vill ta bort den här kunden?")) {

      this.api.delCustomer(this.customer.id).subscribe((data:any) => {
        this.router.navigateByUrl('/admin/allcustomers');
      });
    }
  }

  public openuser(user:User)
  {
    this.router.navigateByUrl('/admin/user/' + user.id);
  }

  public addLog()
  {

    this.new_log.user_id = this.data.currentUser.id;
    this.new_log.customer_id = this.customer.id;

    let time = this.api.getDateStr(new Date(this.new_log.time));
    this.new_log.time = time;

    this.api.setCustomerLog(this.new_log).subscribe((data) => {

      this.customer.logs.unshift(data);
      this.new_log.msg = '';
      this.new_log.time = new Date().toISOString();

      this.api.showSuccess("Yes!", "Händelsen sparades");



    })

  }

}
