import { SportKey } from './sportKey';
import { Media } from './media';


export class SportTeam {

    public id: number;
    public name: string;
    public everysportID: number;
    public sport_key: SportKey;
    public media: Media;

}

