import { Injectable } from '@angular/core';
import { User } from '../../../../shared/models/user';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public currentUser: User = new User();
  public apiToken: String;
  public timeline_start: Date;
  public timeline_stop: Date;
  public interval: any;

  constructor() { }

  public fixDate(dt: any)
  {

  if (dt)
  {
   if (typeof dt.getMonth === 'function')
   {
      let retval = dt.getFullYear() + "-"

      if (dt.getMonth() < 9)
      {
        retval += "0";
      }
      retval += (dt.getMonth()+1) + "-";

      if (dt.getDate() < 10)
      {
        retval += "0";
      }
      retval += dt.getDate() + "T";

      if (dt.getHours() < 10)
      {
        retval += "0";
      }
        retval += dt.getHours() + ":";

      if (dt.getMinutes() < 10)
      {
          retval += "0";
      }
      retval += dt.getMinutes() + ":00.000";

    return retval;

   }
   else
   {
     return dt;
   }
  }
  else
  {
    return dt;
  }

  }
}
