import { Component, OnInit } from '@angular/core';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';


import { examTest } from '../../../../../shared/models/examTest';

@Component({
  selector: 'app-school-test-question',
  templateUrl: './school-test-question.component.html',
  styleUrls: ['./school-test-question.component.scss']
})
export class SchoolTestQuestionComponent implements OnInit {
  heading = 'Edit Exam Question';
  subheading = '';
  icon = 'pe-7s-note icon-gradient bg-arielle-smile';

  public question:examTest;
  public test_id: number;
  public id: number;

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private api: ApiService
  ) {
    this.test_id = +this.route.snapshot.paramMap.get("testid");
    this.id = +this.route.snapshot.paramMap.get("id");
  }

  ngOnInit(): void {
    this.question = new examTest;
    if (this.id == 0) {
      this.question.test_id = this.test_id
    }
    else {
    console.log('Loading question...')
    this.api.getQuestion(this.id).subscribe((data:any) => {
        console.log(data)
        this.question = data;
      })
    }
  }

  change() {
    // console.log(this.question)
  }

  save() {
    console.log('saving', this.question)
    this.api.setQuestion(this.question).subscribe((data:any) => {
      this.api.showSuccess('Saved','Test Saved');
      this.router.navigate(['/chapter/', this.test_id, data.saved.id])
      console.log(data)
    })
  }

  delete() {
    console.log('deleting', this.question)
    this.api.deleteQuestion(this.question).subscribe((data:any) => {
      this.api.showSuccess('Deleted','Question Deleted');
      this.router.navigate(['/chapter/', this.question.chapter_id])
      console.log(data)
    })
  }

}
