import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { environment } from 'src/environments/environment';
import { App } from '../../../../../shared/models/app';
import { CityQuizQuestion } from '../../../../../shared/models/CityQuizQuestion';
import { Media } from '../../../../../shared/models/media';
import { Response } from '../../../../../shared/models/response';

@Component({
  selector: 'app-battle-question-edit',
  templateUrl: './battle-question-edit.component.html',
  styleUrls: ['./battle-question-edit.component.scss']
})
export class BattleQuestionEditComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Skapa fråga';
  subheading = '';
  icon = 'pe-7s-note icon-gradient bg-arielle-smile';

  public cityQuestion: CityQuizQuestion = new CityQuizQuestion();
  public apps: App[] = [];
  public s: String;
  public mediaSearchResult: Media[] = [];

  public dropConfig: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 10,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    url: this.baseUrl + '/api/media/upload?customer_id=-18'
  };
  @ViewChild(DropzoneComponent) DropZone?: DropzoneComponent;

  constructor(public data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router, private toastr: ToastrService) {

    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0)
    {
      this.api.getCityQuizQuestion(id).subscribe((data: CityQuizQuestion) => {
        this.cityQuestion = data;
      })

      this.heading = 'Redigera fråga';

    }
    else
    {
      this.cityQuestion.id = 0;
      this.cityQuestion.apps = []
      this.cityQuestion.medias = []
      this.cityQuestion.status = 1;
      this.cityQuestion.type = 1;

    }

  }

  ngOnInit() {


    this.apps = this.data.currentUser.customer.apps;

    this.api.getMedias(-18).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })

  

  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '2rem',
    placeholder: 'Skriv en fråga här...',
    translate: 'no',
};

  save()
  {

    this.cityQuestion.customer_id = this.data.currentUser.customer.id;
  
    this.api.setcityQuestion(this.cityQuestion).subscribe((data:Response)=>{
      this.cityQuestion.id = data.id;
      this.api.showSuccess("Sparad", "Frågan sparades");
    }, error => this.api.showError(error));
  }


  img_search(){

    this.api.searchMedias(-18, this.s).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })

  }

  addimage(m: Media)
  {

    console.log(m);

    this.cityQuestion.medias.push(m);

  }

  removeImage(index)
  {
    this.cityQuestion.medias.splice(index, 1);
  }


  check_media(m: Media)
  {
    return !this.cityQuestion.medias.find(x => x["id"] === m.id);
  }

  public onUploadInit(args: any): void {
    console.log('onUploadInit:', args);
  }

  public onUploadError(args: any): void {
    console.log('onUploadError:', args);
  }

  public onUploadSuccess(args: any): void {

    let id:number = +args[1];

    this.api.getMedia(id).subscribe((data: Media) => {
      this.cityQuestion.medias.push(data);
      this.DropZone.directiveRef.reset();
    })


  }

  public delete()
  {
    if(confirm("Är du säker på att du vill ta bort den här frågan?")) {

      this.api.delcityQuizQuestion(this.cityQuestion.id).subscribe((data:any) => {
        this.router.navigateByUrl('/battleallquestions');
      });
    }
  }


}

