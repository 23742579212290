import { Component, OnInit } from '@angular/core';
import { EventCategory } from '../../../../../shared/models/eventcategory';
import { App } from '../../../../../shared/models/app';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';

@Component({
  selector: 'app-admin-event-category-edit',
  templateUrl: './admin-event-category-edit.component.html',
  styleUrls: ['./admin-event-category-edit.component.scss']
})
export class AdminEventCategoryEditComponent implements OnInit {

  heading = 'Skapa Eventkategori';
  subheading = 'Här skapar du en ny Eventkategori';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public event: EventCategory = new EventCategory();
  public apps: App[] = [];

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0)
    {

      this.heading = 'Redigera Eventkategori';
      this.subheading = 'Här redigerar du en Eventkategori';

      this.api.getEventCategory(id).subscribe((data: EventCategory) => {
        this.event = data;
      })
    }
    else
    {
      this.event.id = 0;
    }

  }

  ngOnInit() {
    this.apps = this.data.currentUser.apps;
  }



  save()
  {
    this.api.setEventCategory(this.event).subscribe((data:Response)=>{
      this.event.id = data.id;
      this.api.showSuccess("Sparad", "Eventkategorien sparades");
    }, error => this.api.showError(error));

  }

  public delete()
  {
    if(confirm("Är du säker på att du vill ta bort den här eventekatgeorin?")) {

      this.api.delEventCategory(this.event.id).subscribe((data:any) => {
        this.router.navigateByUrl('/admin/alleventcategories');
      });
    }
  }


}

