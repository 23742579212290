export class Today {

    public id: number;
    public month: number;
    public day: number;
    public nameday: string;
    public themedays: string;
    public txt: string;

}

