import { DropzoneConfigInterface, DropzoneComponent } from 'ngx-dropzone-wrapper';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Recommend } from '../../../../../shared/models/recommend';
import { Media } from '../../../../../shared/models/media';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';
import { RecommendCategory } from '../../../../../shared/models/recommendCategory';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-admin-recommend-edit',
  templateUrl: './admin-recommend-edit.component.html',
  styleUrls: ['./admin-recommend-edit.component.scss']
})
export class AdminRecommendEditComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Redigera hemesterställe ';
  subheading = 'Här redigerar du ett hemesterställe';
  icon = 'pe-7s-pen icon-gradient bg-arielle-smile';

  public recommend: Recommend = new Recommend();
  public mediaSearchResult: Media[] = [];
  public preview_str: String;
  public s: String;
  public categories: RecommendCategory[];

  public dropConfig: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    url: this.baseUrl + '/api/media/upload?profile=false&customer_id=' + this.data.currentUser.customer.id
  };
  @ViewChild(DropzoneComponent) DropZone?: DropzoneComponent;


  constructor(private data: DataService, public api: ApiService, private route: ActivatedRoute, private router: Router) {

    this.api.getRecommendCats().subscribe((data: RecommendCategory[]) => {
      this.categories = data;
    })

    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0)
    {
    this.api.getRecommend(id).subscribe((data: Recommend) => {
      this.recommend = data;
    });
    }
    else
    {
      this.heading = 'Skapa hemesterställe ';
      this.subheading = 'Här skapar du ett nytt hemesterställe';
    }

  }

  ngOnInit() {
    this.api.getMedias(-14).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })
  }


  save()
  {
    this.api.setRecommend(this.recommend).subscribe((data:Response) => {
      this.api.showSuccess("Sparad", "Hemestern är sparad");
    }, error => this.api.showError(error))
  }

  markerDragEnd($event)
  {
    this.recommend.latitude = $event.coords.lat;
    this.recommend.longitude = $event.coords.lng;
  }

  img_search(){

    this.api.searchMedias(this.data.currentUser.customer.id, this.s).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })

  }

  addimage(m: Media)
  {
    this.recommend.medias.push(m);
  }

  removeImage(index)
  {
    this.recommend.medias.splice(index, 1);
  }


  check_media(m: Media)
  {
    return !this.recommend.medias.find(x => x["id"] === m.id);
  }

  public onUploadInit(args: any): void {
    console.log('onUploadInit:', args);
  }

  public onUploadError(args: any): void {
    console.log('onUploadError:', args);
  }

  public onUploadSuccess(args: any): void {

    let id:number = +args[1];

    this.api.getMedia(id).subscribe((data: Media) => {
      this.recommend.medias.push(data);
      this.DropZone.directiveRef.reset();
    })
  }

  public delete()
  {
    if(confirm("Är du säker på att du vill ta bort det här inlägget?")) {

      this.api.delRecommend(this.recommend.id).subscribe((data:any) => {
        this.router.navigateByUrl('/admin/allrecommends');
      });
    }
  }

  public unset(id: number)
  {
    this.api.getRecommend(id).subscribe((data: Recommend) => {

      data.status = 0;
      this.api.setRecommend(data).subscribe(x => {

        this.api.getRecommend(this.recommend.id).subscribe((data: Recommend) => {
          this.recommend = data;
        });

      })

    })

  }

  public moveimages(r: Recommend)
  {
    r.medias.forEach(m => {
      this.recommend.medias.push(m);
    })
  }

  nonduplicate(id: number)
  {

    this.api.setRecommendNonDuplicates(this.recommend.id, id).subscribe(x => {
      this.api.getRecommend(this.recommend.id).subscribe((data: Recommend) => {
        this.recommend = data;
      });
    })


  }

  open(id: number)
  {
    this.router.navigateByUrl('/admin/recommend/' + id);
    this.api.getRecommend(id).subscribe((data: Recommend) => {
      this.recommend = data;
    });

  }

  removeadd(id: number)
  {
    this.api.clearRecommendAdd(id).subscribe(x => {

      this.recommend.add = null;

    });
  }

  addMedia(id: number)
  {
    this.api.getMedia(id).subscribe((m:Media) => {
      this.recommend.medias.push(m);
    })
  }

  getCategory(id)
  {
    let cat = "";
    this.categories.forEach((c: RecommendCategory) => {
      if (c.id == id)
      {
        cat = c.name;
      }
    })
    return cat;

  }

}
