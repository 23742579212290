import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { environment } from 'src/environments/environment';
import { App } from '../../../../../shared/models/app';

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.scss']
})
export class SendNotificationComponent implements OnInit {

  baseUrl:string = environment.apiUrl;
  heading = 'Send notification';
  subheading = 'Send notfication to app';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public apps: App[] = [];

  public notification_title: string;
  public notification_body: string;
  public notification_app: App;


  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {


  }

  ngOnInit() {

    this.apps = this.data.currentUser.apps;

  }


  

  public send_notification()
  {

    if (this.notification_title)
    {


      this.api.getApp(this.notification_app.id).subscribe(app => {

        this.api.send_notification(this.notification_title, this.notification_body, "/topics/all", "", app.default_artwork, this.notification_app.id).subscribe(() => {
          this.api.showSuccess("Sent", "A notification was sent");
  
          this.notification_title = "";
          this.notification_body = "";
  
        }, error => this.api.showError(error));

      })


    }


  }


}