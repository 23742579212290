import { Media } from './media';
import { ProfilePlace } from './profile_place';

export class Profile {

    public id: number;
    public name: string;
    public icon: string;
    public profile_img: String;
    public profile_imgs: Media[];
    public profile_places: ProfilePlace[];
    public profile_txt: string;
    public profile_openhours: string;
    public profile_address: string;
    public profile_longitude: string;
    public profile_latitude: string;
    public profile_phone: string;
    public profile_email: string;
    public profile_webb: string;
    public profile_contact_1_name: string;
    public profile_contact_1_email: string;
    public profile_contact_1_title: string;
    public profile_contact_1_phone: string;
    public profile_contact_2_name: string;
    public profile_contact_2_email: string;
    public profile_contact_2_title: string;
    public profile_contact_2_phone: string;
    public profile_contact_3_name: string;
    public profile_contact_3_email: string;
    public profile_contact_3_title: string;
    public profile_contact_3_phone: string;

}

