import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { environment } from 'src/environments/environment';
import { App } from '../../../../../shared/models/app';

@Component({
  selector: 'app-admin-event-generate',
  templateUrl: './admin-event-generate.component.html',
  styleUrls: ['./admin-event-generate.component.scss']
})
export class AdminEventGenerateComponent implements OnInit {

  baseUrl:string = environment.apiUrl;
  heading = 'Generate events';
  subheading = 'Här skapar du ett nytt event';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public apps: App[] = [];
  public app: App;

  public from: string;
  public to: string;


  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
    }


  ngOnInit() {
    this.apps = this.data.currentUser.apps;
  }




  generate()
  {
    this.api.generateEvents(this.from, this.to, this.app.id).subscribe((data) => {
      this.api.showSuccess("Succes", "The events has generated");
    }, error => this.api.showError(error));
  }







}
