import { Component, OnInit, ViewChild } from '@angular/core';
import { SportTeam } from '../../../../../shared/models/sportTeam';
import { SportKey } from '../../../../../shared/models/sportKey';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute } from '@angular/router';
import { Response } from '../../../../../shared/models/response';
import { DropzoneConfigInterface, DropzoneComponent } from 'ngx-dropzone-wrapper';
import { DataService } from 'src/app/Services/data.service';
import { Media } from '../../../../../shared/models/media';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-admin-sport-team-edit',
  templateUrl: './admin-sport-team-edit.component.html',
  styleUrls: ['./admin-sport-team-edit.component.scss']
})


export class AdminSportTeamEditComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Skapa lag (sport)';
  subheading = 'Här skapar du en nytt lag';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public sportteam: SportTeam = new SportTeam();
  public sportKeys: SportKey[] = [];
  public mediaSearchResult: Media[] = [];
  public s: String;

  public dropConfig: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    url: this.baseUrl + '/api/media/upload?customer_id=' + this.data.currentUser.customer.id
  };
  @ViewChild(DropzoneComponent) DropZone?: DropzoneComponent;

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute) {
    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0)
    {

      this.heading = 'Redigera nyckel';
      this.subheading = 'Här redigerar du en sportnyckel';

      this.api.getSportTeam(id).subscribe((data: SportTeam) => {
        this.sportteam = data;
        console.log(this.sportteam);
      })


    }
    else
    {
      this.sportteam.id = 0;
    }

  }

  ngOnInit() {

    this.api.getSportKeys().subscribe((data: SportKey[]) => {
      this.sportKeys = data;
    })

  }

  img_search(){

    this.api.searchMedias(this.data.currentUser.customer.id, this.s).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })

  }

  addimage(m: Media)
  {
    this.sportteam.media = m;
  }

  removeImage(index)
  {
    this.sportteam.media = null;
  }



  save()
  {
    this.api.setSportTeam(this.sportteam).subscribe((data:Response)=>{
      this.sportteam.id = data.id;
      this.api.showSuccess("Sparad", "Laget sparades");
    }, error => this.api.showError(error));
  }

  public onUploadInit(args: any): void {
    console.log('onUploadInit:', args);
  }

  public onUploadError(args: any): void {
    console.log('onUploadError:', args);
  }

  public onUploadSuccess(args: any): void {

    let id:number = +args[1];

    this.api.getMedia(id).subscribe((data: Media) => {
      this.sportteam.media = data;
      this.DropZone.directiveRef.reset();
    })
  }

}
