import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { timer } from 'rxjs';
import { Quiz } from '../../../../../shared/models/quiz';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-quiz-live',
  templateUrl: './quiz-live.component.html',
  styleUrls: ['./quiz-live.component.scss']
})
export class QuizLiveComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  public quiz: Quiz = new Quiz();
  public answered;

  heading = 'Live quiz';
  subheading = 'Här kör du live quiz';
  icon = 'pe-7s-note icon-gradient bg-arielle-smile';

  constructor(private ds: DataService, private api: ApiService, private route: ActivatedRoute,
              private router: Router, private http: HttpClient) {
    let id = +this.route.snapshot.paramMap.get("id");

    this.api.getQuiz(id).subscribe((data: Quiz) => {
      this.quiz = data;

      setTimeout(() => {
        var live = document.getElementById('video_player_preview');
        //@ts-ignore
        if (Hls.isSupported()) {
          //@ts-ignore
          var hls = new Hls();

          hls.loadSource(this.quiz.streaming_url);
          hls.attachMedia(live);
          //@ts-ignore
          hls.on(Hls.Events.MANIFEST_PARSED, function() {
            //@ts-ignore
            live.play();
          });
        }
      }, 2000);
    });

    let pulse = timer(0,5000).subscribe((val) => {
        this.reload();
    });
  }

  ngOnInit() {
  }

  public active(id) {
    this.quiz.live_question = id;
    this.reload();
    this.api.setQuiz(this.quiz).subscribe(data => {});
  }

  reload() {
    if (this.quiz.live_question && this.quiz.live_question != 0) {
      this.http.get(this.baseUrl + '/api/quiz/livequestionanswer?id=' + this.quiz.live_question).subscribe(data => {
        this.answered = data;
        console.log(data);
      });
    }
  }
}
