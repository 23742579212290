import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GiftCard } from '../../../../../shared/models/giftcard';
import { Response } from '../../../../../shared/models/response';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';

@Component({
  selector: 'app-admin-gift-card-edit',
  templateUrl: './admin-gift-card-edit.component.html',
  styleUrls: ['./admin-gift-card-edit.component.scss']
})
export class AdminGiftCardEditComponent implements OnInit {

  heading = 'Skapa presentkort';
  subheading = 'Här skapar du nya presentkort';
  icon = 'pe-7s-phone icon-gradient bg-malibu-beach';

  public giftcard: GiftCard = new GiftCard();

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute) {
    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0)
    {

      this.heading = 'Redigera presenrtkort';
      this.subheading = 'Här redigerar du ett presentkort';

      this.api.getGiftcard(id).subscribe((data: GiftCard) => {
        this.giftcard = data;
      }, error => this.api.showError(error))
    }
    else
    {
      this.api.getGiftcard(-1).subscribe((data: GiftCard) => {
        this.giftcard = data;
        this.giftcard.id = 0;
      }, error => this.api.showError(error))
    }

  }

  ngOnInit() {
  }



  save()
  {
    this.api.setGiftcard(this.giftcard).subscribe((data:Response)=>{
      this.giftcard.id = data.id;
      this.api.showSuccess("Sparad", "Datan sparades");
    });
  }

}
