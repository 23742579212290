import { Component, OnInit } from '@angular/core';
import { AppUser } from '../../../../../shared/models/appuser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { Response } from '../../../../../shared/models/response';
import { App } from '../../../../../shared/models/app';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-admin-app-user-edit',
  templateUrl: './admin-app-user-edit.component.html',
  styleUrls: ['./admin-app-user-edit.component.scss']
})
export class AdminAppUserEditComponent implements OnInit {

  heading = `${this.translateMe("global.createNewSubscriber")}`;
  // subheading = 'Create a new subscriber';
  icon = 'pe-7s-users icon-gradient bg-arielle-smile';

  public user: AppUser = new AppUser();
  // public apps: any[] = [];
  public apps: App[] = [];
  public tempApp: any;
  public getApps: any;

  constructor(private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private data: DataService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {

    let id = +this.route.snapshot.paramMap.get("id");
    this.apps = this.data.currentUser.apps;

    if (id > 0)
    {
        this.api.getAppUser(id).subscribe((data:AppUser) => {
          this.user = data;
         
          this.tempApp = this.apps.find(obj => obj.id === this.user.apps)
       
        })
    } else {
      this.tempApp = this.data.currentUser.default_app
    }
   }

  ngOnInit() {
    this.apps = this.data.currentUser.apps;
  }

  translateMe(key:string) {
    let word = ""
    this.translate.get(key, { value: ""}).subscribe((res: string)=> {word = res})
    return word
  }

  save() {
    // const apps = []

    // this.tempApps.forEach(data => {
    //   apps.push(data.id)
    // });

    // const appsSting = JSON.stringify(apps)
    this.user.apps = this.tempApp.id

    this.api.setAppUser(this.user).subscribe((data:Response) => {
      if (data.code == 200)
      {
        this.toastr.success("User saved", "Saved", { progressBar: true, closeButton: true })
        this.router.navigateByUrl('/admin/allappusers')
      }
    })

  }

  onChange() {
    console.log(this.tempApp)
  }

  public delete()
  {
    this.api.delAppUser(this.user.id).subscribe((data:Response) => {
      console.log(data)
        this.toastr.success("User deleted", "Deleted", { progressBar: true, closeButton: true })
        this.router.navigateByUrl('/admin/allappusers')
    })

  }

}
