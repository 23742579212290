import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Ad } from '../../../../../shared/models/ad';
import { App } from '../../../../../shared/models/app';
import { DataTablesResponse } from '../../../../../shared/models/datatabelsResponse';
import { Feedback } from '../../../../../shared/models/feedback';
import { Media } from '../../../../../shared/models/media';
import { Response } from '../../../../../shared/models/response';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-feedbacks',
  templateUrl: './admin-feedbacks.component.html',
  styleUrls: ['./admin-feedbacks.component.scss']
})
export class AdminFeedbacksComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Respons';
  subheading = '';
  icon = 'pe-7s-speaker icon-gradient bg-arielle-smile';

  public feedbacks: Feedback[] = [];
  public current_feedback: Feedback = new Feedback;
  public current_medias: Media[] = [];
  public app: App;
  public apps: App[] = [];

  constructor(public data: DataService, public api: ApiService, private toastr: ToastrService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
    
    this.apps = this.data.currentUser.apps;
    this.app = this.data.currentUser.default_app;
    this.change();
    
  }

  change()
  {
    this.api.getFeedbacks(this.app.id).subscribe((data: Feedback[]) => {
      this.feedbacks = data;
    })
  }

  getFeedbackType(str: String)
  {
    if (str == "feedback1")
    {
      return "Nyhetstips";
    }
    else if (str == "feedback2")
    {
      return "Eventtips";
    }
    else if (str == "feedback4")
    {
      return "Övrigt";
    }    
    else
    {
      return str;
    }
  }

  setCurrent(feedback: Feedback)
  {

    this.current_feedback = feedback;
    this.current_medias = [];

    this.current_feedback.metas.forEach((meta) => {

      if (meta.meta_key == "images")
      {

        console.log("images");
          meta.meta_value.forEach(media_id => {
            
            console.log(media_id);

            this.api.getMedia(media_id).subscribe((media) => {
              this.current_medias.push(media);
            })


          });
      }

    })

  }


  createAd(feedback: Feedback)
  {
    let ad = new Ad();

    feedback.metas.forEach((meta) => {

      if (meta.meta_key == "images")
      {

          meta.meta_value.forEach(media_id => {
            ad.medias = this.current_medias;
          });
      }

      if (meta.meta_key == "text")
      {
        ad.body = meta.meta_value;
      }
    })

    ad.status = 0;
    ad.title = "Artikel från tipsfunktionen";
    ad.id = 0;
    ad.customer_id = -1;
    let now = new Date();
    let stop = new Date(now.getTime() + 10000*24*60*60*1000);
    ad.timestart = now.toISOString();
    ad.timestop = stop.toISOString();
    ad.byline = this.data.currentUser.default_byline;
    ad.apps.push(this.data.currentUser.default_app);
    ad.preword = "";
    ad.vinjett = "Nyheter";
    ad.notify = 'yes';
    ad.isFree = '0';
    ad.notifyAll = '0';
    let timestart_save = ad.timestart;
    let timestart = this.api.getDateStr(new Date(ad.timestart));
    ad.timestart = timestart;
    let timestop = this.api.getDateStr(new Date(ad.timestop));
    ad.timestop = timestop;

    ad.user_id = this.data.currentUser.id;

    this.api.setAd(ad).subscribe((data:Response)=> {
      let id = data.id;
      this.toastr.success("Nyheten skapades", "Sparad", { progressBar: true, closeButton: true });-

      this.router.navigateByUrl('/news/' + id);


    }, error => this.api.showError(error));


    


  }

}

