export class Contact {

    public id: number;
    public name: string;
    public title: string;
    public email: string;
    public phone: string;

}

