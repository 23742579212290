import { Component, OnInit, ViewChild } from '@angular/core';
import { Byline } from '../../../../../shared/models/byline';
import { Media } from '../../../../../shared/models/media';
import { DropzoneConfigInterface, DropzoneComponent } from 'ngx-dropzone-wrapper';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute } from '@angular/router';
import { Response } from '../../../../../shared/models/response';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-byline-edit',
  templateUrl: './byline-edit.component.html',
  styleUrls: ['./byline-edit.component.scss']
})
export class BylineEditComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Redigera byline';
  subheading = 'Här redigerar du en byline';
  icon = 'pe-7s-pen icon-gradient bg-arielle-smile';

  public byline: Byline = new Byline();
  public mediaSearchResult: Media[] = [];
  public preview_str: String;
  public s: String;

  public dropConfig: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    url: this.baseUrl + '/api/media/upload?profile=false&customer_id=' + this.data.currentUser.customer.id
  };
  @ViewChild(DropzoneComponent) DropZone?: DropzoneComponent;

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute) {
    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0) {
      this.api.getByline(id).subscribe((data: Byline) => {
        this.byline = data;
      });
    }
  }

  ngOnInit() {
    this.api.getMedias(this.data.currentUser.customer.id).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })
  }

  save() {
    this.api.setByline(this.byline).subscribe((data:Response) => {
      this.api.showSuccess("Sparad", "Bylinen är sparad");
    }, error => this.api.showError(error))
  }

  img_search() {
    this.api.searchMedias(this.data.currentUser.customer.id, this.s).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })
  }

  addimage(m: Media) {
    this.byline.media = m;
  }

  removeImage(index) {
    this.byline.media = null;
  }

  public onUploadInit(args: any): void {
    console.log('onUploadInit:', args);
  }

  public onUploadError(args: any): void {
    console.log('onUploadError:', args);
  }

  public onUploadSuccess(args: any): void {
    let id:number = +args[1];

    this.api.getMedia(id).subscribe((data: Media) => {
      this.byline.media = data;
      this.DropZone.directiveRef.reset();
    })
  }
}
