import { Component, OnInit } from '@angular/core';
import { SportKey } from '../../../../../shared/models/sportKey';
import { App } from '../../../../../shared/models/app';
import { Customer } from '../../../../../shared/models/customer';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { Template } from '../../../../../shared/models/template';
import { Response } from '../../../../../shared/models/response';

@Component({
  selector: 'app-admin-sport-key-edit',
  templateUrl: './admin-sport-key-edit.component.html',
  styleUrls: ['./admin-sport-key-edit.component.scss']
})
export class AdminSportKeyEditComponent implements OnInit {

  heading = 'Skapa nyckel';
  subheading = 'Här skapar du en ny sportnyckel';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public sportkey: SportKey = new SportKey();
  public apps: App[] = [];
  public customers: Customer[] = [];
  public templates: Template[] = [];

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute) {
    let id = +this.route.snapshot.paramMap.get("id");
    
    this.api.getCustomers().subscribe((data: Customer[]) => {
      this.customers = data;
    })
    this.api.getApps().subscribe((data: App[]) => {
      this.apps = data;
    })
    this.api.getTemplates().subscribe((data: Template[]) => {
      this.templates = data;
    })

    if (id != 0)
    {

      this.heading = 'Redigera nyckel';
      this.subheading = 'Här redigerar du en sportnyckel';

      this.api.getSportKey(id).subscribe((data: SportKey) => {
        this.sportkey = data;
      })
    }
    else
    {
      this.sportkey.id = 0;
    }

  }

  ngOnInit() {
  }

 

  save()
  {
    this.api.setSportKey(this.sportkey).subscribe((data:Response)=>{
      this.sportkey.id = data.id;
      this.api.showSuccess("Sparad", "Nyckeln sparades");
    }, error => this.api.showError(error));
  }

}

