import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Services/data.service';
import { environment } from 'src/environments/environment';
import { DataTablesResponse } from '../../../../../shared/models/datatabelsResponse';
import { Notification } from '../../../../../shared/models/notification';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'All notifications';
  subheading = '';
  icon = 'pe-7s-medal icon-gradient bg-malibu-beach';

  public notifications: Notification[] = [];

  dtOptions: DataTables.Settings = {};

  constructor(private data: DataService, private api: ApiService, private http: HttpClient) {
  }

  ngOnInit(): void {
    const that = this;

    this.dtOptions = {
      order: [[ 0, "desc" ]],
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      language: {
        "emptyTable":     "Tabellen innehåller ingen data",
        "info":           "Visar rad _START_ - _END_ av _TOTAL_ rader",
        "infoEmpty":      "Visar 0 av 0 rader",
        "infoFiltered":   "(av totalt _MAX_ rader)",
        "infoPostFix":    "",
        "thousands":      ",",
        "lengthMenu":     "Visa _MENU_ rader per sida",
        "loadingRecords": "Laddar...",
        "processing":     "Laddar...",
        "search":         "Sök:",
        "zeroRecords":    "Inget innehåll hittat",
        "paginate": {
          "first":      "Första",
          "last":       "Sista",
          "next":       "Nästa",
          "previous":   "Föregående"
        },
        "aria": {
            "sortAscending":  ": activate to sort column ascending",
            "sortDescending": ": activate to sort column descending"
        }
    },
      ajax: (dataTablesParameters: any, callback) => {
        that.http.post<DataTablesResponse>(
            this.baseUrl + '/api/notifications',
            dataTablesParameters, this.api.getAuthorizationHeader()
        ).subscribe(resp => {
          // console.log(resp.data)
          that.notifications = resp.data;

          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [{ data: 'id' }, { data: 'title' }, { data: 'app' }, { data: 'notify_at' }, { data: 'notified_at' }]
    };
  }

  ngOnDestroy(): void {
  }

  getLocalTime(UTC) {
    if (UTC==null) {
      return
    } else {
      var year = UTC.slice(0,4)
      var month = UTC.slice(5,7)
      var day = UTC.slice(8,10)
      var hour = UTC.slice(11,13)
      var minute = UTC.slice(14,16)
      var second = UTC.slice(17,19)
     
      var UTCDate = Date.UTC(year, month, day, hour, minute, second)
      
      let newDate = new Date(UTCDate).toLocaleDateString( "sv-SE" );
      let newTime = new Date(UTCDate).toLocaleTimeString( "sv-SE" );
      
      return newDate +" "+ newTime
    }
   
  }
}
