import { Customer } from './customer';
import { App } from './app';
import { EventCategory } from './eventcategory';
import { Media } from './media';
import { AudioShow } from './audioShow';

export class Event {

    public id: number;
    public starttime: string;
    public endtime: string;
    public showend: number;
    public wholeday: number;
    public place: string;
    public title: string;
    public txt: string;
    public customer: Customer;
    public audioShow: AudioShow;
    public media: Media;
    public app: App;
    public categories: EventCategory[];
    public template: Boolean;
    public weekday: number;
    public isLive: number;
    public isRerun: number;
}

