import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Media } from '../../../../../shared/models/media';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CropperComponent } from 'angular-cropperjs';
import { Response } from '../../../../../shared/models/response';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-media-crop',
  templateUrl: './media-crop.component.html',
  styleUrls: ['./media-crop.component.scss']
})
export class MediaCropComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Beskär bild';
  subheading = 'Här kan du beskära bilden till olika format';
  icon = 'pe-7s-photo icon-gradient bg-plum-plate';

  public media: Media = new Media();
  public base64: any = "";

  public aspection_ratio:String = "16:9";
  public ratio;

  public src_thmb: any;
  public src_full: any;

  public btn_thmb: string = "LÅS TUMMNAGELN";
  public thmb_locked = false;

  constructor(private api: ApiService, private route: ActivatedRoute,
    private router: Router, private http: HttpClient,
    private toastr: ToastrService) {
    let id = +this.route.snapshot.paramMap.get("id");

    this.api.getMedia(id).subscribe((data: Media) => {
      this.media = data;

      getBase64ImageFromUrl(this.baseUrl + '/uploads/' + data.path)
      .then(result => this.base64 = result)
      .catch(err => console.error(err));
    })
  }

  @ViewChild('angularCropper') public angularCropper: ImageCropperComponent;
  @ViewChild('img1') public img1: ElementRef;
  @ViewChild('img2') public img2: ElementRef;
  @ViewChild('imgbox') public imgbox: ElementRef;

  imgUrl = null;

  imageCropped(event: ImageCroppedEvent) {
    this.src_full = event.base64;

    if (!this.thmb_locked) {
      this.src_thmb = this.src_full;
    }

    this.ratio = event.width / event.height;
    let cs = getComputedStyle(this.imgbox.nativeElement);
    let width = this.imgbox.nativeElement.offsetWidth - parseFloat(cs.paddingLeft) - parseFloat(cs.paddingRight);
    if (this.ratio < 1.5) {
        this.img1.nativeElement.height = width / 1.5;
        this.img2.nativeElement.height = width / 1.5;
        this.img1.nativeElement.width = width;
        this.img2.nativeElement.width = this.img2.nativeElement.height * this.ratio;

        this.imgbox.nativeElement.style.height = (width / 1.5) + "px";

        let _width = this.img2.nativeElement.height * this.ratio;
        let _left = (width - _width) / 2;
        this.img2.nativeElement.style.left = _left + "px";
    }
    else {
        this.img1.nativeElement.width = width;
        this.img2.nativeElement.width = width;
        this.img1.nativeElement.height = width / this.ratio;
        this.img2.nativeElement.height = width / this.ratio;
        this.imgbox.nativeElement.style.height = (width / this.ratio) + "px";
        this.img2.nativeElement.style.left = "0px";
    }
  }

  ngOnInit() {
  }

  change_ratio(event) {
    if (event == "16:9") {
      this.angularCropper.aspectRatio = 1.77778; //.change_ratio(16/9); //.cropper.setAspectRatio(16/9);
      this.angularCropper.maintainAspectRatio = true;
    }
    if (event == "3:2") {
      this.angularCropper.aspectRatio = 1.5; // .change_ratio(3/2); //.cropper.setAspectRatio(3/2);
      this.angularCropper.maintainAspectRatio = true;
    }
    if (event == "1:1") {
     // this.angularCropper.cropper.setAspectRatio(1);
     this.angularCropper.aspectRatio = 1;
     this.angularCropper.maintainAspectRatio = true;
    }
    if (event == "free") {
      this.angularCropper.maintainAspectRatio = false;
    }
  }

  save(copy) {
    this.media.src = this.src_full;

    if (this.thmb_locked) {
      this.media.src_thmb = this.src_thmb;
    }

    this.api.replaceMedia(this.media, copy).subscribe((data: Response) => {
      this.toastr.success("bilden sparades", "Sparad", {  progressBar: true, closeButton: true });
      this.router.navigateByUrl('/medias');
    }, error => this.api.showError(error))
  }

  lock_thmb() {
    this.thmb_locked = !this.thmb_locked;

    if (this.thmb_locked) {
      this.btn_thmb = "LÅS UPP TUMNAGELN";
    }
    else {
      this.btn_thmb = "LÅS TUMMNAGELN";
    }
  }
}

async function getBase64ImageFromUrl(imageUrl) {
  var res = await fetch(imageUrl);
  var blob = await res.blob();

  return new Promise((resolve, reject) => {
    var reader  = new FileReader();
    reader.addEventListener("load", function () {
        resolve(reader.result);
    }, false);

    reader.onerror = () => {
      return reject(this);
    };
    reader.readAsDataURL(blob);
  })
}
