import { ProductCategory } from './../../../../../shared/models/productCategory';
import { Product } from './../../../../../shared/models/product';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';
import { Media } from '../../../../../shared/models/media';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Skapa produkt';
  subheading = 'Här skapar du en produkt till ditt showroom.';
  icon = 'pe-7s-id icon-gradient bg-malibu-beach';

  public dropConfig: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 10,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    url: this.baseUrl + '/api/media/upload?customer_id=' + this.data.currentUser.customer.id
  };

  @ViewChild(DropzoneComponent) DropZone?: DropzoneComponent;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '2rem',
    placeholder: 'Skriv texten här...',
    translate: 'no'
  };

  public product: Product = new Product();
  public categories: ProductCategory[];
  public s: String;
  public mediaSearchResult: Media[];

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
      this.api.getProductCategories().subscribe((data: ProductCategory[]) => {
        this.categories = data;
      })

    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0) {
      this.heading = 'Redigera produkten';
      this.subheading = 'Här redigerar du en undersida';

      this.api.getProduct(id).subscribe((data: Product) => {
        this.product = data;
      }, error => this.api.showError(error))
    }
    else {
      this.product.id = 0;
      this.product.customer_id = this.data.currentUser.customer.id;
    }
  }

  ngOnInit() {
    this.api.getMedias(this.data.currentUser.customer.id).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
      console.log(this.mediaSearchResult);
    })
  }

  img_search() {
    this.api.searchMedias(this.data.currentUser.customer.id, this.s).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })
  }

  addimage(m: Media) {
    this.product.medias.push(m);
  }

  removeImage(index) {
    this.product.medias.splice(index, 1);
  }

  check_media(m: Media) {
    if (this.product.medias) {
        return !this.product.medias.find(x => x["id"] === m.id);
    }
    return true;
  }

  public onUploadInit(args: any): void {
    console.log('onUploadInit:', args);
  }

  public onUploadError(args: any): void {
    console.log('onUploadError:', args);
  }

  public onUploadSuccess(args: any): void {
    let id:number = +args[1];

    this.api.getMedia(id).subscribe((data: Media) => {
      this.product.medias.push(data);
      this.DropZone.directiveRef.reset();
    })
  }

  save() {
    this.api.setProduct(this.product).subscribe((data:Response)=>{
        this.product.id = data.id;
        this.api.showSuccess("Sparad", "Produkten sparades.");
    }, error => this.api.showError(error));
  }

  public delete() {
    if(confirm("Är du säker på att du vill ta bort den här produkten?")) {
      this.api.delProduct(this.product.id).subscribe((data:any) => {
        this.router.navigateByUrl('/products');
      });
    }
  }
}
