import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';

@Component({
  selector: 'app-stats-news',
  templateUrl: './stats-news.component.html',
  styleUrls: ['./stats-news.component.scss']
})
export class StatsNewsComponent implements OnInit {

  heading = 'Statistik';
  subheading = '';
  icon = 'pe-7s-note icon-gradient bg-arielle-smile';

  public statdata: any;
  public lastweekstatdata: any;
  public loaded:boolean = false;



  constructor(public data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {

  }

  ngOnInit() {

    this.api.getNewsStat(null).subscribe((data:any) => {
      this.statdata = data;
      this.loaded = true;
    })

    this.api.getNewsStat(-1).subscribe((data:any) => {
      this.lastweekstatdata = data;
      this.loaded = true;
    })


  }

  open(id:number)
  {
    this.router.navigateByUrl('/news/' + id);
  }





}
