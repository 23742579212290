import { Component, OnInit } from '@angular/core';
import { Lunch } from '../../../../../shared/models/lunch';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';

export interface week{
  date:string;
  name:string;
}

@Component({
  selector: 'app-lunch-edit',
  templateUrl: './lunch-edit.component.html',
  styleUrls: ['./lunch-edit.component.scss']
})

export class LunchEditComponent implements OnInit {

  heading = 'Skapa lunchvecka';
  subheading = 'Här skapar du en ny lunchvecka';
  icon = 'pe-7s-up-arrow icon-gradient bg-malibu-beach';

  public lunch: Lunch = new Lunch();

  public weeks: week[] = [];


  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
    let id = +this.route.snapshot.paramMap.get("id");

    this.loadLunch(id);

    let currentdate = new Date();

    this.weeks.push({ date: this.formateDate(this.getMonday(currentdate)), name: this.formateWeek(this.getMonday(currentdate)) })
    this.weeks.push({ date: this.formateDate(this.addWeeks(this.getMonday(currentdate), 1)), name: this.formateWeek(this.addWeeks(this.getMonday(currentdate), 1)) })
    this.weeks.push({ date: this.formateDate(this.addWeeks(this.getMonday(currentdate), 2)), name: this.formateWeek(this.addWeeks(this.getMonday(currentdate), 2)) })
    this.weeks.push({ date: this.formateDate(this.addWeeks(this.getMonday(currentdate), 3)), name: this.formateWeek(this.addWeeks(this.getMonday(currentdate), 3)) })
    this.weeks.push({ date: this.formateDate(this.addWeeks(this.getMonday(currentdate), 4)), name: this.formateWeek(this.addWeeks(this.getMonday(currentdate), 4)) })



  }

  private loadLunch(id: number)
  {

    if (id != 0)
    {

      this.heading = 'Redigera dagens lunch';
      this.subheading = 'Här redigerar du en lunchvecka';

      this.api.getLunch(id).subscribe((data: Lunch) => {
        this.lunch = data;

        let found = false;
        this.weeks.forEach(week => {
          if (week.date == this.lunch.datum)
          { found = true; }
        });

        if (!found)
        {
          var parts =this.lunch.datum.split('-');
          var mydate = new Date(+parts[0], +parts[1] - 1, +parts[2]);
          this.weeks.push({ date: this.formateDate(this.getMonday(mydate)), name: this.formateWeek(this.getMonday(mydate)) })
        }

      })
    }
    else
    {
      this.lunch = new Lunch();

      this.lunch.id = 0;
      this.lunch.datum = this.formateDate(this.getMonday(new Date()));

      this.api.getStandardLunch().subscribe((data) => {

        data.forEach(element => {

          if (element.meta_key == "everyday") { this.lunch.everyday = element.meta_value; }
          if (element.meta_key == "day_1") { this.lunch.day_1 = element.meta_value; }
          if (element.meta_key == "day_2") { this.lunch.day_2 = element.meta_value; }
          if (element.meta_key == "day_3") { this.lunch.day_3 = element.meta_value; }
          if (element.meta_key == "day_4") { this.lunch.day_4 = element.meta_value; }
          if (element.meta_key == "day_5") { this.lunch.day_5 = element.meta_value; }

        });

      })

    }

  }

  ngOnInit() {

    this.route.params.subscribe(routeParams => {
      this.loadLunch(routeParams.id);
    });

  }

  save()
  {
    this.api.setLunch(this.lunch).subscribe((data:Response)=>{
        this.lunch.id = data.id;
        this.api.showSuccess("Sparad", "Lunchveckan sparades");
    }, error => this.api.showError(error));

  }

  private getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  private formateDate(d)
  {
        d = new Date(d);

        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  private formateWeek(d)
  {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    var yearStart:any = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    return "Vecka " + weekNo + " " + d.getFullYear();
  }

  private addWeeks(date, weeks) {
    var result = new Date(date);
    result.setDate(result.getDate() + (weeks * 7));
    return result;
  }


}
