import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-battles',
  templateUrl: './battles.component.html',
  styleUrls: ['./battles.component.scss']
})
export class BattlesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
