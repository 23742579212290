import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { Router, Route, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-school-progress',
  templateUrl: './school-progress.component.html',
  styleUrls: ['./school-progress.component.scss']
})
export class SchoolProgressComponent implements OnInit {

  public userId: number = 0
  private appId: number
  public userProgress: any;
  public user: any;
  public selectedChapter: number = -1

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private api: ApiService,
    private toastr: ToastrService
  ) { 
    this.userId = +this.route.snapshot.paramMap.get("userid");
    this.appId = +this.route.snapshot.paramMap.get("appid");
    console.log('User',this.userId,'App', this.appId)
  }

  ngOnInit(): void {
    console.log('school progress')
    // console.log('user ID',103, 'app ID', 199)
    this.load()
  }

  load() {
    this.api.getUserProgress(this.userId, this.appId).subscribe((data:any) => {
      this.userProgress = data.data;
      this.user = data.user;
      console.log(this.userProgress)
      this.userProgress.map(item => {
        if (!item.locked  && item.exam_locked) {
          this.selectedChapter = item.id
          return item.id
        }
      })
    })
  }

  toggleViewChapter(id) {
    if (this.selectedChapter === id) {
      this.selectedChapter = -1
    } else {
      this.selectedChapter = id
    }
  }

  toggleLock(userId: number, type: string, id: number, value: number) {
    let confirmation:boolean
    if (value) {
      confirmation = confirm("Do you wish to unlock?")
    }
    else {
      confirmation = confirm("Do you wish to lock?")
    }
   
    // let val:number = value 
    if (confirmation) {
      let postObject = {
        userId: userId,
        value: value,
        type: type,
        id: id
      }
      console.log(postObject)
      this.api.updateUserProgress(postObject).subscribe((data:any) => {
        // this.userProgress = data.data;
        if (postObject.value) {
          this.toastr.success("Chapter is unlocked", "Saved", { progressBar: true, closeButton: true })
        }
        else {
          this.toastr.success("Chapter is locked", "Saved", { progressBar: true, closeButton: true })
        }
        console.log(data)
        this.load()
      })
    }
  }

  redoExam(test_id:number) {
    let postObject = {
      userId: this.userId,
      value: 1,
      type: 'test_id',
      id: test_id
    }
    // console.log(postObject)
    this.api.updateUserProgress(postObject).subscribe((data:any) => {
      this.api.removeUserExamAnswers(postObject).subscribe((data:any) => {
        console.log('Everything removed: ', data)
        this.toastr.success("The result of the exam is failed ", "Saved", { progressBar: true, closeButton: true })
        this.load()
      })
      
    })
    
  }

  completeChapter(id: number) {
    let postObject = {
      userId: this.userId,
      value: 1,
      type: 'chapter_id',
      id: id
    }
    this.api.updateUserProgressCompleteChapter(postObject).subscribe((data:any) => {
      this.toastr.success("Chapter is completed", "Saved", { progressBar: true, closeButton: true })
      console.log("Chapter Completed: ",data)
      this.load()
    })
  }

  getBackgroundColor( obj: any) {
    if (obj.complete) {
      return 'lawngreen'
    }
    if (obj.exam_locked) {
      return 'orange'
    }
    else {
      return 'none'
    }
  }

  redoBtn(obj) {
    if (obj.complete) {
      return true
    }

  }

  approveBtn(obj) {
    if (obj.complete) {
      return true
    }
  }
}
