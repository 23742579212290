import { Tag } from './tag';

export class Media {

    public id!: number;
    public src!: string;
    public src_thmb!: string;
    public type!: string;
    public tags: Tag[] = [];
    public foto!: String;
    public description!: String;
    public name!: string;
    public path!: string;
    public video_url!: string;

}

