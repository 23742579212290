import { Component, OnInit } from '@angular/core';
import { App } from '../../../../../shared/models/app';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';
import { Place } from '../../../../../shared/models/place';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Media } from '../../../../../shared/models/media';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-admin-apps-edit',
  templateUrl: './admin-apps-edit.component.html',
  styleUrls: ['./admin-apps-edit.component.scss']
})
export class AdminAppsEditComponent implements OnInit {

  baseUrl:string = environment.apiUrl;
  heading = 'Skapa app';
  subheading = 'Här skapar du en ny app';
  icon = 'pe-7s-phone icon-gradient bg-malibu-beach';

  public app: App = new App();
  public places: Place[] = [];
  public apps: App[] = [];
  public mediaSearchResult: Media[] = [];
  public s: String;

  public dropConfig: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    url: this.baseUrl + '/api/media/upload?profile=false&customer_id=' + this.data.currentUser.customer.id
  };

  editorOptions = {theme: 'vs', language: 'html'};

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0)
    {

      this.heading = 'Redigera app';
      this.subheading = 'Här redigerar du en app';

      this.api.getApp(id).subscribe((data: App) => {
        this.app = data;
      }, error => this.api.showError(error))
    }
    else
    {
      this.app.id = 0;
    }

  }

  ngOnInit() {
    this.api.getPlaces().subscribe((data: Place[]) => {
      this.places = data;
    });
    this.api.getApps().subscribe((data: App[]) => {
      this.apps = data;
    })
    this.api.getMedias(this.data.currentUser.customer.id).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })
  }

  img_search(){

    this.api.searchMedias(this.data.currentUser.customer.id, this.s).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })

  }

  addimage(m: Media)
  {
    console.log(m)
    // this.app.media = m;
  }

  save()
  {
    console.log(this.app)
    this.api.setApp(this.app).subscribe((data:Response)=>{
      this.app.id = data.id;
      this.api.showSuccess("Sparad", "Datam sparades");
    });
  }

}
