export class socialMediaObject { 
    public id!: number;
    public sort!: number;
    public name!: string;
    public icon!: String;
    public androidUrl!: String;
    public androidIdentifier!: string;
    public iOSurl!: String;
    public iosIdentifier!: string;
    public appStore!: string;
  }