import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/Services/data.service';

import { App } from '../../../../../shared/models/app';
import { Ad } from '../../../../../shared/models/ad';
import { Chapter } from '../../../../../shared/models/chapter';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { DataTablesResponse } from '../../../../../shared/models/datatabelsResponse';
import { environment } from '../../../environments/environment';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-school-courses',
  templateUrl: './school-courses.component.html',
  styleUrls: ['./school-courses.component.scss']
})
export class SchoolCoursesComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Chapters';
  subheading = '';
  icon = 'pe-7s-photo icon-gradient bg-malibu-beach';

  public ads: Chapter[] = [];
  public apps: App[];
  public selectedApps: App[] = [];
  public changed:boolean = false;

  dtOptions: DataTables.Settings = {};

  constructor(
    private data: DataService,
    private http: HttpClient,
    private api: ApiService,
    private route: ActivatedRoute, 
    private router: Router,
    private toastr: ToastrService
  ) {
    this.apps = this.data.currentUser.apps;
    this.selectedApps = this.data.currentUser.selectedApps;
   }

  ngOnInit(): void {
    const that = this;

    let appstr = "";

    if (this.selectedApps) {
      this.selectedApps.forEach(x => {
        appstr += x.id + ","
      })

      appstr += "-1"
    }
    // console.log(this.selectedApps[0].id)

    this.dtOptions = {
      order: [[ 1, "desc" ]],
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      language: {
        "emptyTable":     "The table is empty",
        "info":           "Visar rad _START_ till _END_ av _TOTAL_ rader",
        "infoEmpty":      "Visar post 0 till 0 av 0 poster",
        "infoFiltered":   "(av totalt _MAX_ poster)",
        "infoPostFix":    "",
        "thousands":      ",",
        "lengthMenu":     "Visa _MENU_ rader per sida",
        "loadingRecords": "Laddar...",
        "processing":     "Laddar...",
        "search":         "Sök:",
        "zeroRecords":    "Inget matchar sökningen",
        "paginate": {
            "first":      "Första",
            "last":       "Sista",
            "next":       "Nästa",
            "previous":   "Föregående"
        },
        "aria": {
            "sortAscending":  ": activate to sort column ascending",
            "sortDescending": ": activate to sort column descending"
        }
    },
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post<DataTablesResponse>(
            this.baseUrl + '/api/chapters?&appstr=' + appstr,
            dataTablesParameters, {}
          ).subscribe(resp => {

            that.ads = resp.data;
            // this.ads.forEach((x, i) => {
            //   x.sort = i + 1;
            // })

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [{ data: 'title' }, { data: 'timestart' }]
    };
  }

  change() {
    this.data.currentUser.selectedApps = this.selectedApps;
    this.router.navigateByUrl('/course/0', { skipLocationChange: true }).then(
      x => {
        this.router.navigateByUrl('/courses', { skipLocationChange: true });
      }
    )
  }

  saveSort() {
    // console.log({chapters: this.ads})
   
    this.api.setChapterOrder({chapters: this.ads}).subscribe(
      x => {
        this.changed = false;
        // console.log(x)
        this.toastr.success("Changed order saved", "Saved", { progressBar: true, closeButton: true });
      }
    )
  }

  addTest(chapter_id) {
    this.api.getNewTestId(chapter_id).subscribe(
      x => {
        console.log(x.data.test_id)
        this.router.navigate(['/test/' + x.data.test_id])
      }
    )
    // this.router.navigate(['/course/0'])
  }

  changeorder(index, type)
  {

    // console.log(index, type);
    this.changed = true;
    let new_list:any[] = [];

    if (type == 1)
    {
      
      this.ads.forEach((item, i) => {

        if (i == index-1)
        {

          new_list.push(this.ads[i+1])
          new_list.push(this.ads[i])

        }
        else if (i == index)
        {}
        else
        {
          new_list.push(item);
        }

      })

    }

    if (type == -1)
    {
      
      this.ads.forEach((item, i) => {

        if (i == index)
        {

          new_list.push(this.ads[i+1])
          new_list.push(this.ads[i])

        }
        else if (i == index+1)
        {}
        else
        {
          new_list.push(item);
        }

      })

    }


    this.ads = new_list;

  }

}
