import { Template } from '../../../../../shared/models/template';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { Ad } from '../../../../../shared/models/ad';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { Response } from '../../../../../shared/models/response';
import { ActivatedRoute, Router } from '@angular/router';
import { App } from '../../../../../shared/models/app';
import { Media } from '../../../../../shared/models/media';
import { DropzoneConfigInterface, DropzoneComponent } from 'ngx-dropzone-wrapper';
import { Toast, ToastrService } from 'ngx-toastr';
import { Byline } from '../../../../../shared/models/byline';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-ad-edit',
  templateUrl: './ad-edit.component.html',
  styleUrls: ['./ad-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdEditComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Skapa annons';
  subheading = '';
  icon = 'pe-7s-note icon-gradient bg-arielle-smile';

  public ad: Ad = new Ad();
  public apps: App[] = [];
  public s: String;
  public mediaSearchResult: Media[] = [];
  public preview_str: String;
  public preview_bodystr: String;
  public newreactivate;
  public number_of_ads: number = 0;
  public templates: Template[] = [];

  public dropConfig: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 10,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    url: this.baseUrl + '/api/media/upload?customer_id=' + this.data.currentUser.customer.id
  };
  @ViewChild(DropzoneComponent) DropZone?: DropzoneComponent;
  @ViewChild('preview_card') public preview_card: ElementRef;
  @ViewChild('ad_preview') public ad_preview: ElementRef;


  constructor(public data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router, private toastr: ToastrService) {

    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0)
    {
      this.api.getAd(id).subscribe((data: Ad) => {
        this.ad = data;
        this.preview();
      })
    }
    else
    {
      this.ad.id = 0;
      this.ad.customer_id = this.data.currentUser.customer.id;
      this.ad.apps = this.data.currentUser.customer.apps;
      let now = new Date();
      let stop = new Date(now.getTime() + 7*24*60*60*1000);
      this.ad.timestart = now.toISOString();
      this.ad.timestop = stop.toISOString();
      this.ad.status = 1;
      this.ad.isOffer = "0";

    }

  }

  ngOnInit() {

    this.api.getMonthlyAds(this.data.currentUser.customer.id).subscribe((data:any) => {
      this.number_of_ads = data;
    })


    this.apps = this.data.currentUser.customer.apps;

    this.api.getMedias(this.data.currentUser.customer.id).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })

    this.api.getTemplates().subscribe((data: Template[]) => {
      this.templates = data;
    })

  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '2rem',
    placeholder: 'Skriv annonstexten här...',
    translate: 'no',
};

  save()
  {
    if (this.ad.body)
    {
      this.ad.body = this.ad.body.split('&#10;').join(' ');
    }
    this.ad.customer_id = this.data.currentUser.customer.id;
    let timestart_save = this.ad.timestart;
    let timestart = this.api.getDateStr(new Date(this.ad.timestart));
    this.ad.timestart = timestart;
    let timestop_save = this.ad.timestop;
    let timestop = this.api.getDateStr(new Date(this.ad.timestop));
    this.ad.timestop = timestop;

    this.ad.user_id = this.data.currentUser.id;

    this.api.setAd(this.ad).subscribe((data:Response)=>{
      this.ad.id = data.id;
      this.ad.timestart = timestart_save;
      this.ad.timestop = timestop_save;
      this.api.showSuccess("Sparad", "Annonsen sparades");
    }, error => this.api.showError(error));
  }

  preview()
  {
    if (this.ad.body)
    {
      this.ad.body = this.ad.body.split('&#10;').join(' ');
    }

    this.api.previewAd(this.ad).subscribe((data:any)=>{
      this.preview_str = data.feed;
      this.preview_bodystr = data.full;

      if (!document.getElementById("template_css_" + data.feed_template_id))
      { $("head").append('<style id="template_css_' + data.feed_template_id + '" type="text/css">' + data.feed_css + '</style>'); }
      else
      { $("#template_css_" + data.feed_template_id).text(data.feed_css); }

      if (!document.getElementById("template_css_" + data.full_template_id))
      { $("head").append('<style id="template_css_' + data.full_template_id + '" type="text/css">' + data.full_css + '</style>'); }
      else
      { $("#template_css_" + data.full_template_id).text(data.full_css); }


      let width = this.preview_card.nativeElement.offsetWidth - 40;
      let zoom = width / 1000;
      this.ad_preview.nativeElement.style.zoom = zoom ;

      setTimeout( function() {

        var timeElements = document.getElementsByClassName('ad_time');
        for(var i = 0; i < timeElements.length; i++)
        {
          let time = new Date(timeElements[i].getAttribute('datetime'));
          //@ts-ignore
          timeElements[i].innerText = TimeAgo.inWords(time.getTime());
        }

      //@ts-ignore
      var slider = tns({ container: '.bd_slider',
      items: 1,
      autoHeight: false,
      controls: false,
      nav: false,
      speed: 400,
      autoplay: true,
      mouseDrag: true });
      },1);
    });
  }

  img_search(){

    this.api.searchMedias(this.data.currentUser.customer.id, this.s).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })

  }

  addimage(m: Media)
  {
    this.ad.medias.push(m);
    this.preview();
  }

  removeImage(index)
  {
    this.ad.medias.splice(index, 1);
    this.preview();
  }


  check_media(m: Media)
  {
    return !this.ad.medias.find(x => x["id"] === m.id);
  }

  public onUploadInit(args: any): void {
    console.log('onUploadInit:', args);
  }

  public onUploadError(args: any): void {
    console.log('onUploadError:', args);
  }

  public onUploadSuccess(args: any): void {

    let id:number = +args[1];

    this.api.getMedia(id).subscribe((data: Media) => {
      this.ad.medias.push(data);
      this.DropZone.directiveRef.reset();
    })


  }

  public delete()
  {
    if(confirm("Är du säker på att du vill ta bort den här annonsen?")) {

      this.api.delAd(this.ad.id).subscribe((data:any) => {
        this.router.navigateByUrl('/ads');
      });
    }
  }

  public addReactivate()
  {


     let obj = {
      reactivate: this.newreactivate.toLocaleString('sv-SV', { timeZone: 'Europe/Stockholm' }),
      reactivated: null
    }
    this.ad.reactivates.push(obj);
    this.newreactivate = null;
  }

  public delReactivate(index)
  {
    this.ad.reactivates.splice(index, 1);
  }


}
