import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { App } from '../../../../../shared/models/app';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { environment } from 'src/environments/environment';
import { menuObject } from '../../../../../shared/models/menuObject';


@Component({
  selector: 'app-admin-tab-menu',
  templateUrl: './admin-tab-menu.component.html',
  styleUrls: ['./admin-tab-menu.component.scss']
})
export class AdminTabMenuComponent implements OnInit {

  baseUrl:string = environment.apiUrl;
  heading = 'Administrera menyn';
  subheading = 'Här ändrar du menyn';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public apps: App[];
  public app: App;
  public editId: number;
  public menuObjects: menuObject[];

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
  

  }

  ngOnInit() {
    this.apps = this.data.currentUser.apps;
    this.app = this.data.currentUser.default_app;

    this.api.getTabMenu(this.app.id).subscribe((data) => {
      this.menuObjects = data;
    })


  }

  app_change()
  {

    this.api.getTabMenu(this.app.id).subscribe((data) => {
      this.menuObjects = data;
    })  

  }


  add_row()
  {
    let new_id = 0;
    this.menuObjects.forEach(item => {
      if (item.id > new_id)
      {
        new_id = item.id;
      }
    })

    let newObject = new menuObject();
    newObject.id =new_id + 1;

    this.menuObjects.push(newObject);
    this.editId = new_id;

  }


  changeorder(index, type)
  {

    console.log(index, type);

    let new_list:menuObject[] = [];

    if (type == 1)
    {
      
      this.menuObjects.forEach((item, i) => {

        if (i == index-1)
        {

          new_list.push(this.menuObjects[i+1])
          new_list.push(this.menuObjects[i])

        }
        else if (i == index)
        {}
        else
        {
          new_list.push(item);
        }

      })

    }

    if (type == -1)
    {
      
      this.menuObjects.forEach((item, i) => {

        if (i == index)
        {

          new_list.push(this.menuObjects[i+1])
          new_list.push(this.menuObjects[i])

        }
        else if (i == index+1)
        {}
        else
        {
          new_list.push(item);
        }

      })

    }


    this.menuObjects = new_list;

  }

  save()
  {

    this.api.setTabMenu(this.menuObjects, this.app.id).subscribe(() => {
      this.api.showSuccess("Datan sparades!", "Menus är sparad");
    },
    (error) => {
      this.api.showError(error);
    })

  }

  remove(i)
  {
    this.menuObjects.splice(i, 1);
    this.editId = 0;
  }





}
