import {Component} from '@angular/core';

@Component({
  selector: 'app-megapopover',
  templateUrl: './megapopover.component.html'
})

export class MegapopoverComponent {
  name = 'World';
}
