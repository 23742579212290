import { QuizQuestion } from './quizQuestion';

export class Quiz {

    public id: number;
    public name: String;
    public customer_id: number;
    public typ: string;
    public distance: number;
    public description: string;
    public questions: QuizQuestion[] = [];
    public starttime: string;
    public stoptime: string;
    public status: number;
    public streaming_url: string;
    public live_question: number;
    public vimeoid: string;
    public participants: any[] = [];

}

