export class examTest { 
    public id!: number;
    public test_id!: number;
    public sort!: number;
    public question!: string;
    public chapter_id!: number;
    public correct!: number;
    public answer1!: string;
    public answer2!: string;
    public answer3!: string;
    public answer4!: string;
    public answer5!: string;
  }