import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { environment } from 'src/environments/environment';
import { App } from '../../../../../shared/models/app';
import { Chat } from '../../../../../shared/models/chat';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss']
})
export class ChatsComponent implements OnInit {

  baseUrl:string = environment.apiUrl;
  heading = 'Administrera menyn';
  subheading = 'Här ändrar du menyn';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public apps: App[];
  public app: App;
  public chats: Chat[];

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
  

  }

  ngOnInit() {


    this.apps = this.data.currentUser.apps;
    this.app = this.data.currentUser.default_app;

    if (!this.app)
    {
      this.app = this.apps[0];
    }

    this.api.getActiveChats(this.app.id).subscribe((data) => {
      this.chats = data;
    })


  }

  open(device_id)
  {
    alert("open" + device_id);
  }


  app_change()
  {
    
    this.api.getActiveChats(this.app.id).subscribe((data) => {
      this.chats = data;
    })

  }



}

