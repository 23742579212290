import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Lunch } from '../../../../../shared/models/lunch';
import { Response } from '../../../../../shared/models/response';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { week } from '../lunch-edit/lunch-edit.component';

@Component({
  selector: 'app-lunch-standard',
  templateUrl: './lunch-standard.component.html',
  styleUrls: ['./lunch-standard.component.scss']
})
export class LunchStandardComponent implements OnInit {

  heading = 'Standardlunchvecka';
  subheading = 'Här skapar du en ny standardlunchvecka';
  icon = 'pe-7s-up-arrow icon-gradient bg-malibu-beach';

  public lunch: Lunch = new Lunch();

  public weeks: week[] = [];


  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
  }


  ngOnInit() {

    this.api.getStandardLunch().subscribe((data) => {


      data.forEach(element => {

        if (element.meta_key == "everyday") { this.lunch.everyday = element.meta_value; }
        if (element.meta_key == "day_1") { this.lunch.day_1 = element.meta_value; }
        if (element.meta_key == "day_2") { this.lunch.day_2 = element.meta_value; }
        if (element.meta_key == "day_3") { this.lunch.day_3 = element.meta_value; }
        if (element.meta_key == "day_4") { this.lunch.day_4 = element.meta_value; }
        if (element.meta_key == "day_5") { this.lunch.day_5 = element.meta_value; }

      });

    })


  }

  save()
  {
    this.api.setStandardLunch(this.lunch).subscribe((data:Response)=>{
        this.api.showSuccess("Sparad", "Standardlunchveckan sparades");
    }, error => this.api.showError(error));

  }



}
