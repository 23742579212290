import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { App } from '../../../../../shared/models/app';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { environment } from 'src/environments/environment';
import { socialMediaObject } from '../../../../../shared/models/socialMedia';

@Component({
  selector: 'app-admin-social-media',
  templateUrl: './admin-social-media.component.html',
  styleUrls: ['./admin-social-media.component.scss']
})
export class AdminSocialMediaComponent implements OnInit {

  baseUrl:string = environment.apiUrl;
  heading = 'Administrera menyn';
  subheading = 'Här ändrar du menyn';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public apps: App[];
  public app: App;
  public editId: number;
  public socialMediaObjects: socialMediaObject[];

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
  

  }

  ngOnInit() {
    this.apps = this.data.currentUser.apps;
    this.app = this.data.currentUser.default_app;

    this.api.getSocialMedia(this.app.id).subscribe((data) => {
      this.socialMediaObjects = data;
      console.log(this.socialMediaObjects)
    })


  }

  app_change()
  {

    this.api.getSocialMedia(this.app.id).subscribe((data) => {
      this.socialMediaObjects = data;
      console.log(this.socialMediaObjects)
    })  

  }

  add_row()
  {
    console.log('add row')
    let new_id = 0;
    this.socialMediaObjects.forEach(item => {
      if (item.id > new_id)
      {
        new_id = item.id;
      }
    })

    let newObject = new socialMediaObject();
    newObject.id =new_id + 1;

    this.socialMediaObjects.push(newObject);
    this.editId = new_id;

  }

  changeorder(index, type)
  {

    console.log(index, type);

    let new_list:socialMediaObject[] = [];

    if (type == 1)
    {
      
      this.socialMediaObjects.forEach((item, i) => {

        if (i == index-1)
        {

          new_list.push(this.socialMediaObjects[i+1])
          new_list.push(this.socialMediaObjects[i])

        }
        else if (i == index)
        {}
        else
        {
          new_list.push(item);
        }

      })

    }

    if (type == -1)
    {
      
      this.socialMediaObjects.forEach((item, i) => {

        if (i == index)
        {

          new_list.push(this.socialMediaObjects[i+1])
          new_list.push(this.socialMediaObjects[i])

        }
        else if (i == index+1)
        {}
        else
        {
          new_list.push(item);
        }

      })

    }


    this.socialMediaObjects = new_list;

  }

  save()
  {
    console.log(this.socialMediaObjects)
    this.api.setSocialMedia(this.socialMediaObjects, this.app.id).subscribe(() => {
      this.api.showSuccess("Datan sparades!", "Social Media accounts are saved!");
    },
    (error) => {
      this.api.showError(error);
    })

  }

  remove(i)
  {
    this.socialMediaObjects.splice(i, 1);
    this.editId = 0;
  }

}
