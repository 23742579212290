import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { environment } from 'src/environments/environment';
import { App } from '../../../../../shared/models/app';
import { AudioEpisode } from '../../../../../shared/models/audioEpisode';
import { AudioShow } from '../../../../../shared/models/audioShow';
import { Notification } from '../../../../../shared/models/notification';
import { Response } from '../../../../../shared/models/response';

@Component({
  selector: 'app-notification-edit',
  templateUrl: './notification-edit.component.html',
  styleUrls: ['./notification-edit.component.scss']
})
export class NotificationEditComponent implements OnInit {

  baseUrl:string = environment.apiUrl;
  heading = 'Send notification';
  subheading = 'Send notfication to app';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public apps: App[] = [];

  public notification: Notification;
  public audioShows: AudioShow[];
  public audioEpisodes: AudioEpisode[];

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {


  }

  ngOnInit() {

    this.apps = this.data.currentUser.apps;

    let id = +this.route.snapshot.paramMap.get("id");

    if (id)
    {

      this.api.getNotification(id).subscribe(data => {
        this.notification = data;
        this.getAudioShows();

      })

    }
    else
    {
      this.notification = new Notification;
      this.notification.notify_at = new Date("2099-01-01 12:00").toISOString();
      this.notification.app = this.data.currentUser.default_app;

      this.getAudioShows();

    }

  }

 public getAudioShows()
 {

    this.api.getAudioShows(this.notification.app.id).subscribe(data => {
      this.audioShows = data;

      this.getAudioEpisodes();

    });
 }

 public getAudioEpisodes()
 {
    if (this.notification.audioShow)
    {
        this.api.getAudioShowsEpisodes(this.notification.audioShow.id).subscribe(data => {
          this.audioEpisodes = data;
        });
    }
 }

  public save_notification()
  {


    this.api.getApp(this.notification.app.id).subscribe(app => {

      this.notification.image = app.default_artwork;

      this.api.setNotification(this.notification).subscribe((data:Response)=>{
        this.notification.id = data.id;
        this.api.showSuccess("Saved", "Notification is saved");
        if (data.code == 200)
        {
          this.router.navigateByUrl('/admin/notifications')
        }
    }, error => this.api.showError(error));

    })



  }


}