import { Component, OnInit } from '@angular/core';
import { Place } from '../../../../../shared/models/place';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute } from '@angular/router';
import { Response } from '../../../../../shared/models/response';

@Component({
  selector: 'app-admin-place-edit',
  templateUrl: './admin-place-edit.component.html',
  styleUrls: ['./admin-place-edit.component.scss']
})
export class AdminPlaceEditComponent implements OnInit {

  heading = 'Skapa plats';
  subheading = 'Här skapar du nya platser';
  icon = 'pe-7s-phone icon-gradient bg-malibu-beach';

  public place: Place = new Place();

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute) {
    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0)
    {

      this.heading = 'Redigera plats';
      this.subheading = 'Här redigerar du en plats';

      this.api.getPlace(id).subscribe((data: Place) => {
        this.place = data;
      }, error => this.api.showError(error))
    }
    else
    {
      this.place.id = 0;
    }

  }

  ngOnInit() {
  }

 

  save()
  {
    this.api.setPlace(this.place).subscribe((data:Response)=>{
      this.place.id = data.id;
      this.api.showSuccess("Sparad", "Datan sparades");
    });
  }

}