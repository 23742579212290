import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { environment } from 'src/environments/environment';
import { App } from '../../../../../shared/models/app';
import { DataTablesResponse } from '../../../../../shared/models/datatabelsResponse';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-admin-event-templates',
  templateUrl: './admin-event-templates.component.html',
  styleUrls: ['./admin-event-templates.component.scss']
})
export class AdminEventTemplatesComponent implements OnInit {

  public apps: App[];
  public app: App;
  public dayOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  baseUrl:string = environment.apiUrl;
  heading = 'Alla events';
  subheading = 'Här redigerar du alla events';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public events: Event[] = [];

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;



  activeDayIsOpen: boolean = true;

  constructor(private modal: NgbModal, private http: HttpClient,
    private data: DataService, private api: ApiService, private router: Router, private translate: TranslateService) {
  }

  ngOnInit(): void {

    this.apps = this.data.currentUser.apps;
    this.app = this.data.currentUser.default_app;

    if (!this.app)
    {
      this.data.currentUser.default_app = this.apps[0];
      this.app = this.apps[0];
    }

    this.load_events();

  }

  ngOnDestroy(): void {

  }

  translateMe(key:string) {
    let word = ""
    this.translate.get(key, { value: ""}).subscribe((res: string)=> {word = res})
    return word
  }

  load_events(){

    const that = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      responsive: true,
      "order": [[ 0, 'desc' ]],
      language: {
        "emptyTable":     "This table is empty",
        "info":           "Showing row _START_ - _END_ out of _TOTAL_ rows",
        "infoEmpty":      "Show 0 of 0 rows",
        "infoFiltered":   "(av totalt _MAX_ rader)",
        "infoPostFix":    "",
        "thousands":      ",",
        "lengthMenu":     `${this.translateMe("global.view")} _MENU_ ${this.translateMe("global.rows-per-page")}`,
        "loadingRecords": "Loading...",
        "processing":     "Loading...",
        "search":         `${this.translateMe("global.search")}`,
        "zeroRecords":    "No content found",
        "paginate": {
          "first":      `${this.translateMe("global.first")}`,
          "last":       `${this.translateMe("global.last")}`,
          "next":      `${this.translateMe("global.next")}`,
          "previous":   `${this.translateMe("global.previous")}`,
        },
        "aria": {
            "sortAscending":  ": activate to sort column ascending",
            "sortDescending": ": activate to sort column descending"
        }
    },
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post<DataTablesResponse>(
            this.baseUrl + '/api/events?app_id=' + this.app.id + "&templates=1",
            dataTablesParameters, this.api.getAuthorizationHeader()
          ).subscribe(resp => {

            that.events = resp.data;

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [{ data: 'id' }, { data: 'title' }, { data: 'time' }]
    };

  }


  app_change()
  {

    this.data.currentUser.default_app = this.app;

    this.api.setUser(this.data.currentUser).subscribe(() => {

      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload();
      });


    });


  }




}

