import { Component, OnInit } from '@angular/core';
import { Device } from '../../../../../shared/models/device';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-followers',
  templateUrl: './followers.component.html',
  styleUrls: ['./followers.component.scss']
})
export class FollowersComponent implements OnInit {
  heading = 'Dina följare';
  subheading = '';
  icon = 'pe-7s-speaker icon-gradient bg-arielle-smile';

  public devices: Device[] = [];
  public loading = true;


  constructor(public data: DataService, private api: ApiService) {
  }

  ngOnInit() {

    this.api.getFollowers().subscribe((data: Device[]) => {
      this.devices = data;
      this.loading = false;
    })

  }

  ngOnDestroy(): void {
  }



}
