import { Component, OnInit, ViewChild } from '@angular/core';
import { Quiz } from '../../../../../shared/models/quiz';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';
import { QuizQuestion } from '../../../../../shared/models/quizQuestion';
import { DropzoneConfigInterface, DropzoneComponent } from 'ngx-dropzone-wrapper';
import { Media } from '../../../../../shared/models/media';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-quiz-edit',
  templateUrl: './quiz-edit.component.html',
  styleUrls: ['./quiz-edit.component.scss']
})
export class QuizEditComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Skapa ny quiz';
  subheading = 'Här skapar du en quiz';
  icon = 'pe-7s-note icon-gradient bg-arielle-smile';

  public quiz: Quiz = new Quiz();
  public question: QuizQuestion;
  public question_edit_index:number;
  public s: string;
  public mediaSearchResult: Media[] = [];
  public load_id: number;

  public dropConfig: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    url: this.baseUrl + '/api/media/upload?profile=true&customer_id=' + this.ds.currentUser.customer.id
  };
  @ViewChild(DropzoneComponent) DropZone?: DropzoneComponent;

  constructor(public ds: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {
      let id = +this.route.snapshot.paramMap.get("id");
      this.loadContent(id);
  }

  ngOnInit() {
    this.route.params.subscribe(routeParams => {
      this.loadContent(routeParams.id);
    });
  }

  public loadQuiz() {
    this.api.getQuiz(this.load_id).subscribe((data: Quiz) => {
      this.quiz = data;
      this.quiz.id = 0;
      this.quiz.customer_id = this.ds.currentUser.customer.id;

      this.quiz.questions.forEach((data, index)=> {
          this.quiz.questions[index].id = 0;
      })
    })
  }

  private loadContent(id: number) {
    if (id != 0) {
      this.heading = 'Redigera en quiz';
      this.subheading = 'Här redigerar du dina quiz.';

      this.api.getQuiz(id).subscribe((data: Quiz) => {
        this.quiz = data;
      });
    }
    else {
      this.quiz.id = 0;
      this.quiz.customer_id = this.ds.currentUser.customer.id;
      this.quiz.status = 0;
    }
  }

  save() {

    let starttime_save = this.quiz.starttime;
    let starttime = this.api.getDateStr(new Date(this.quiz.starttime));
    this.quiz.starttime = starttime;
    let stoptime_save = this.quiz.stoptime;
    let stoptime = this.api.getDateStr(new Date(this.quiz.stoptime));
    this.quiz.stoptime = stoptime;

    this.api.setQuiz(this.quiz).subscribe((data: Response) => {
      this.quiz.starttime = starttime_save;
      this.quiz.stoptime = stoptime_save;
      this.quiz.id = data.id;
      this.api.showSuccess("Sparad", "Quizen sparades");
    }, error => this.api.showError(error));
  }

  public delete() {
    if(confirm("Är du säker på att du vill ta bort den här Quizzen?")) {
      this.api.delQuiz(this.quiz.id).subscribe((data:any) => {
        this.router.navigateByUrl('/quizzes');
      });
    }
  }

  doTextareaValueChange(ev) {
    try {
      this.question.question = ev.target.value;
      this.quiz.questions[this.question_edit_index].question = ev.target.value;
    } catch(e) {
      console.info('could not set textarea-value');
    }
  }

  moveQuestionUp(i) {
    if (i > 0) {
      if (this.question_edit_index && i == this.question_edit_index) {
        this.question_edit_index = i-1;
      }

      let tempQuestion = JSON.parse(JSON.stringify(this.quiz.questions[i-1]));;
      this.quiz.questions[i-1] = this.quiz.questions[i];
      this.quiz.questions[i] = tempQuestion;
    }
  }

  moveQuestionDown(i) {
    if (i < this.quiz.questions.length -1) {
      if (this.question_edit_index && i == this.question_edit_index) {
        this.question_edit_index = i+1;
      }

      let tempQuestion = JSON.parse(JSON.stringify(this.quiz.questions[i+1]));
      this.quiz.questions[i+1] = this.quiz.questions[i];
      this.quiz.questions[i] = tempQuestion;
    }
  }

  deleteQuestion(i) {
    this.quiz.questions.splice(i, 1);
  }

  addQuestion() {
    if (!this.quiz.questions) {
      this.quiz.questions = [];
    }

    let item = new QuizQuestion;
    item.id = 0;
    item.typ = 'n3';

    this.quiz.questions.push(item);
    this.question_edit_index = this.quiz.questions.length -1;
    this.question = this.quiz.questions[this.question_edit_index];
  }

  editQuestion(i) {
    this.question_edit_index = i;
    this.question = this.quiz.questions[this.question_edit_index];
  }

  saveQuestion() {
    this.quiz.questions[this.question_edit_index] = this.question;
    this.question = null;
  }

  addimage(m: Media) {
    this.question.media = m;
  }

  removeImage(index) {
    this.question.media = null;
  }

  public onUploadInit(args: any): void {
    console.log('onUploadInit:', args);
  }

  public onUploadError(args: any): void {
    console.log('onUploadError:', args);
  }

  public onUploadSuccess(args: any): void {
    let id:number = +args[1];

    this.api.getMedia(id).subscribe((data: Media) => {
      this.question.media = data;
      this.DropZone.directiveRef.reset();
    })
  }

  img_search() {
    this.api.searchMedias(this.ds.currentUser.customer.id, this.s).subscribe((data:Media[]) => {
      this.mediaSearchResult = data;
    })
  }
}
