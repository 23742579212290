import { Component, OnInit } from '@angular/core';
import { Customer } from '../../../../../shared/models/customer';
import { App } from '../../../../../shared/models/app';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';

@Component({
  selector: 'app-sales-warnings',
  templateUrl: './sales-warnings.component.html',
  styleUrls: ['./sales-warnings.component.scss']
})
export class SalesWarningsComponent implements OnInit {

  heading = 'Avtal';
  subheading = 'Hantera kunder';
  icon = 'pe-7s-note icon-gradient bg-arielle-smile';

  public customers: Customer[];
  public apps: App[];
  public selectedApps: App[] = [];
  public total = 0;


  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {

      this.apps = this.data.currentUser.apps;
      this.selectedApps = this.data.currentUser.selectedApps;

      this.app_change();


  }

  ngOnInit() {

  }

  app_change()
  {

    this.data.currentUser.selectedApps = this.selectedApps;

    this.api.getCustomersWithwarnings(this.selectedApps).subscribe((data: Customer[]) => {
      this.customers = data;

      this.total = 0;


      this.customers = this.customers.sort((obj1, obj2) => {
        if (obj1.score > obj2.score) {
            return -1;
        }

        if (obj1.score < obj2.score) {
            return 1;
        }

        return 0;
    });



    })
  }

  open(customer_id: number, contract_id)
  {
    this.api.getCustomer(customer_id).subscribe((data: Customer) => {
      this.data.currentUser.customer = data;
      this.api.setUser(this.data.currentUser).subscribe((data: Response) => {
        sessionStorage.setItem("url", "/contract/" + contract_id);
        this.router.navigateByUrl('/login');
      })

    })


  }



}
