import { Component, OnInit } from '@angular/core';
import { Serie } from '../../../../../shared/models/serie';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-serie-edit',
  templateUrl: './serie-edit.component.html',
  styleUrls: ['./serie-edit.component.scss']
})
export class SerieEditComponent implements OnInit {

  public heading: string;
  public subheading: string;
  public icon: string;

  public serie: Serie = new Serie();

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router, private toastr:ToastrService) {
  }

  ngOnInit() {

    this.heading = 'Skapa artikelserie';
    this.subheading = 'Här skapar du nya artikelserier';
    this.icon = 'pe-7s-network icon-gradient bg-malibu-beach';

    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0)
    {

      this.heading = 'Redigera artikelserie';
      this.subheading = 'Här redigerar du en artikelserie';

      this.api.getSerie(id).subscribe((data: Serie) => {
        this.serie = data;
      })
    }
    else
    {
      this.serie.id = 0;
    }


  }

  save()
  {
    this.api.setSerie(this.serie).subscribe((data:Response)=>{
      this.serie.id = data.id;
      this.toastr.success("Artikelserien sparades", "Sparad", {  progressBar: true, closeButton: true });
    });
  }

  open(id:number)
  {
    this.router.navigateByUrl('/news/' + id);
  }

}
