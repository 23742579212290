import {Component, OnInit} from '@angular/core';
import {ThemeOptions} from '../../../../../theme-options';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { Response } from '../../../../../../../../shared/models/response';
import { Router } from '@angular/router';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit {
  baseUrl:string = environment.apiUrl;

  toggleDrawer() {
    this.globals.toggleDrawer = !this.globals.toggleDrawer;
  }

  constructor(public globals: ThemeOptions, public data: DataService,
    private api: ApiService, private router: Router) {
  }

  ngOnInit() {
  }

  onChangeCustomer(event)
  {
    this.api.setUser(this.data.currentUser).subscribe((data: Response) => {
      sessionStorage.setItem("url", "");
      this.router.navigateByUrl('/login');
    })
  }

  logout() {
    localStorage.setItem("id", null);
    sessionStorage.setItem("id", null);
    this.data.currentUser = null;

    this.router.navigateByUrl('/login');
  }

}
