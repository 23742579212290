import { App } from './app';
import { Media } from './media';
import { Event } from './event';

export class AudioShow {

    public id: number;
    public title: String;
    public status: number;
    public type: string;
    public url: string;
    public body: String;
    public media: Media;
    public media2: Media;
    public apps: App[] = [];
    public events: Event[] = [];
    public notification: number;
    public notification_title: String;
    public notification_body: String;


}

