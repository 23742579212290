import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleChartInterface } from 'ng2-google-charts';

@Component({
  selector: 'app-stats-general',
  templateUrl: './stats-general.component.html',
  styleUrls: ['./stats-general.component.scss']
})
export class StatsGeneralComponent implements OnInit {

  heading = 'Statistik';
  subheading = '';
  icon = 'pe-7s-note icon-gradient bg-arielle-smile';

  public statdata: any;
  public loaded:boolean = false;

  public hourChart: GoogleChartInterface = {
    chartType: 'LineChart',
    dataTable: null,
    //firstRowIsData: true,
    options: {'title': '',
    curveType: 'function',
    legend: { position: 'bottom' },
    series: {
      0:{ targetAxisIndex: 0 },
      1:{ targetAxisIndex: 0 },
      2:{ targetAxisIndex: 1 },
    },
    vAxis: {
      baselineColor: 'none',
      baseline: 0,
      ticks: []
    },
    hAxis: {
      ticks: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
    }
  },
  };

  public weekChart: GoogleChartInterface = {
    chartType: 'LineChart',
    dataTable: null,
    //firstRowIsData: true,
    options: {'title': '',
    curveType: 'function',
    legend: { position: 'bottom' },
    series: {
      0:{ targetAxisIndex: 0 },
      1:{ targetAxisIndex: 0 },
      2:{ targetAxisIndex: 1 },
    },
    vAxis: {
      baselineColor: 'none',
      baseline: 0,
      ticks: []
    },
    hAxis: {
      ticks: ["Mån", "Tis", "Ons", "Tors", "Fre", "Lör", "Sön"]
    }
  },
  };


  constructor(public data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {

  }

  ngOnInit() {

    this.api.getStat().subscribe(data => {
      this.statdata = data;
      this.loaded = true;

      let linedata = []

      if (this.data.currentUser.customer.id == -1)
      {
        linedata.push(['Timmar', 'Medel i appen', 'Nyheters besökare', 'Publicerade nyheter' ])
      }
      else
      {
        let name = this.data.currentUser.customer.name;
        if (!name.endsWith('s'))
        {
          name = name + "s"
        }
        linedata.push(['Timmar', 'Medel i appen', name + ' besökare', name + ' annonser' ])
      }

      let tah_total = 0;
      let ah_total = 0;
      let ad_total = 0;

      data.stat.total_activity_hour.forEach(x => { tah_total = tah_total + x.Antal });
      data.stat.activity_hour.forEach(x => { ah_total = ah_total + x.Antal });
      data.stat.ads_hour.forEach(x => { ad_total = ad_total + x.Antal });

      data.stat.total_activity_hour.forEach(tah => {

        let item = []
        item.push(tah.hour)
        item.push(tah.Antal * 100 / tah_total);

        let ah_value = 0;
        data.stat.activity_hour.forEach(ah => {
          if (tah.hour == ah.hour) { ah_value = ah.Antal; }
        });
        item.push(ah_value * 100 / ah_total);

        let ad_value = 0;
        data.stat.ads_hour.forEach(ah => {
          if (tah.hour == ah.hour) { ad_value = ah.Antal; }
        });
        item.push(ad_value * 100  /ad_total);

        linedata.push(item);

      });

      this.hourChart.dataTable = linedata;


      let weekdata = []

      if (this.data.currentUser.customer.id == -1)
      {
        weekdata.push(['Veckodag', 'Medel i appen', this.data.currentUser.customer.name + 's besökare', 'Publicerade nyheter' ])
      }
      else
      {
        weekdata.push(['Veckodag', 'Medel i appen', this.data.currentUser.customer.name + 's besökare', this.data.currentUser.customer.name + 's annonser' ])
      }

      tah_total = 0;
      ah_total = 0;
      ad_total = 0;

      data.stat.total_activity_day.forEach(x => { tah_total = tah_total + x.Antal });
      data.stat.activity_day.forEach(x => { ah_total = ah_total + x.Antal });
      data.stat.ads_day.forEach(x => { ad_total = ad_total + x.Antal });

      data.stat.total_activity_day.forEach(tah => {

        let item = []
        if (tah.day == 1) { item.push("Sön") }
        if (tah.day == 2) { item.push("Mån") }
        if (tah.day == 3) { item.push("Tis") }
        if (tah.day == 4) { item.push("Ons") }
        if (tah.day == 5) { item.push("Tors") }
        if (tah.day == 6) { item.push("Fre") }
        if (tah.day == 7) { item.push("Lör") }

        item.push(tah.Antal * 100 / tah_total);

        let ah_value = 0;
        data.stat.activity_day.forEach(ah => {
          if (tah.day == ah.day) { ah_value = ah.Antal; }
        });
        item.push(ah_value * 100 / ah_total);

        let ad_value = 0;
        data.stat.ads_day.forEach(ah => {
          if (tah.day == ah.day) { ad_value = ah.Antal; }
        });
        item.push(ad_value * 100  /ad_total);

        weekdata.push(item);

      });

      let newdata = [];
      newdata.push(weekdata[0]);
      newdata.push(weekdata[2]);
      newdata.push(weekdata[3]);
      newdata.push(weekdata[4]);
      newdata.push(weekdata[5]);
      newdata.push(weekdata[6]);
      newdata.push(weekdata[7]);
      newdata.push(weekdata[1]);

      console.log(newdata);

      this.weekChart.dataTable = newdata;


    })


  }




}

