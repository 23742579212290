import { Component, OnInit, ViewChild } from '@angular/core';
import { DropzoneConfigInterface, DropzoneComponent } from 'ngx-dropzone-wrapper';
import { DataService } from 'src/app/Services/data.service';
import { Media } from '../../../../../shared/models/media';
import { ApiService } from 'src/app/Services/api.service';
import { HttpClient } from '@angular/common/http';
import { Response } from '../../../../../shared/models/response';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-media-upload',
  templateUrl: './media-upload.component.html',
  styleUrls: ['./media-upload.component.scss']
})
export class MediaUploadComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Ladda upp bilder';
  subheading = '';
  icon = 'pe-7s-photo icon-gradient bg-malibu-beach';

  public type = 'component';

  public disabled = false;

  public medias: Media[] = [];

  public auto = [];

  public config: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 50,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    timeout: 120000,
    url: this.baseUrl + '/api/media/upload?customer_id=' + this.data.currentUser.customer.id
  };

  @ViewChild(DropzoneComponent) componentRef?: DropzoneComponent;

  constructor(private data: DataService, private api: ApiService, private http: HttpClient) { 
  }

  ngOnInit() {
  }

  public onUploadInit(args: any): void {
    console.log('onUploadInit:', args);
  }

  public onUploadError(args: any): void {
    console.log('onUploadError:', args);
  }

  public onUploadSuccess(args: any): void {
    let id:number = +args[1];

    this.api.getMedia(id).subscribe((data: Media) => {
      this.medias.push(data);
      this.componentRef.directiveRef.reset();
    })
  }

  onTextChange(event) {
      this.http.get(this.baseUrl + "/api/tags?s=" + event).subscribe((data:any) => {
        this.auto = data.response.split("<£>");
      })
  }

  save(media: Media) {
    this.api.setMedia(media).subscribe((data:Response)=> {
      this.api.showSuccess("Sparades", "Datan sparades");

    }, error => { 
      this.api.showError(error) 
    });
  }

  saveAll() {
    this.medias.forEach(media => {
      this.save(media);
    })
  }
}
