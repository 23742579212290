import { Component, OnInit } from '@angular/core';
import { Media } from '../../../../../shared/models/media';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-media-edit',
  templateUrl: './media-edit.component.html',
  styleUrls: ['./media-edit.component.scss']
})
export class MediaEditComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Redigera bild';
  subheading = 'Här byter du namn på bilden, lägger till fotograf, bildtext och taggar eller tar bort bilden.';
  icon = 'pe-7s-photo icon-gradient bg-malibu-beach';

  public auto = [];

  public media: Media = new Media();

  public autocompleteItems = ['Javascript', 'Typescript', 'Rust', 'Go'];

  constructor(private api: ApiService, private route: ActivatedRoute,
    private router: Router, private http: HttpClient,
    private toastr: ToastrService) {
    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0) {
      this.api.getMedia(id).subscribe((data: Media) => {
        this.media = data;
      })
    }
    else {
      this.media.id = 0;
    }
  }

  ngOnInit() {
  }

  onTextChange(event) {
    this.http.get(this.baseUrl + "/api/tags?s=" + event).subscribe((data:any) => {
      this.auto = data.response.split("<£>");
    })
  }

  save(crop) {
    this.api.setMedia(this.media).subscribe((data:Response)=> {
      this.toastr.success("Datan sparades", "Sparad", {  progressBar: true, closeButton: true });

      if (crop == true) {
        this.router.navigateByUrl('/crop/' + this.media.id);
      }
    });
  }

  public delete() {
    if(confirm("Är du säker på att du vill ta bort den här bilden?")) {
      this.api.delMedia(this.media.id).subscribe((data:any) => {
        this.router.navigateByUrl('/medias');
      });
    }
  }
}
