export class Lunch {

    public id: number;
    public datum: String;
    public day_1: String;
    public day_2: String;
    public day_3: String;
    public day_4: String;
    public day_5: String;
    public day_6: String;
    public day_7: String;
    public everyday: String;
}

