import { App } from './app';
import { AudioShow } from './audioShow';

export class Notification {

    public id: number;
    public title: string;
    public body: string;
    public link: string;
    public image: string;
    public target: string;
    public targetid: number;
    public app: App;
    public audioShow: AudioShow;
    public audio_episode_id: number;
    public notify_at: string;
    public notified_at: string;

}

