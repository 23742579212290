import { Customer } from './customer';
import { App } from './app';
import { Template } from './template';

export class Rss {

    public id: number;
    public name: String;
    public intervall: number;
    public last_rum: String;
    public customer: Customer;
    public app: App;
    public template_full: Template;
    public template_feed: Template;
    public keywords: String;
    public type: String;
    public status: number;
    public ad_status: number;
    public ad_max: number;
    public url: String;
}

