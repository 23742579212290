import { Component, OnInit, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Customer } from '../../../../../shared/models/customer';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';
import { Profile } from '../../../../../shared/models/profile';
import { Media } from '../../../../../shared/models/media';
import { DropzoneConfigInterface, DropzoneComponent } from 'ngx-dropzone-wrapper';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Redigera profilsida';
  subheading = 'Här redigerar du din profilsida';
  icon = 'pe-7s-note icon-gradient bg-arielle-smile';

  public profile: Profile = new Profile();
  public mediaSearchResult: Media[] = [];
  public preview_str: String;
  public s: String;

  public dropConfig: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    url: this.baseUrl + '/api/media/upload?profile=true&customer_id=' + this.data.currentUser.customer.id
  };
  @ViewChild(DropzoneComponent) DropZone?: DropzoneComponent;
  @ViewChild('preview_card') public preview_card: ElementRef;
  @ViewChild('profile_preview') public profile_preview: ElementRef;

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router, private toastr: ToastrService) {
    this.api.getProfile(this.data.currentUser.customer.id).subscribe((data: Profile) => {
      this.profile = data;
      this.preview();
    });
  }

  ngOnInit() {
    this.api.getMedias(this.data.currentUser.customer.id).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '2rem',
    placeholder: 'Skriv en beskrivande text här...',
    translate: 'no'
  };

  save() {
    this.api.setProfile(this.profile).subscribe((data:Response) => {
      this.toastr.success("Profilen sparades", "Sparad", {  progressBar: true, closeButton: true });
      this.preview();
    })
  }

  preview() {
    this.api.previewProfile(this.profile).subscribe((data:any)=>{
      this.preview_str = data.full;

      let width = this.preview_card.nativeElement.offsetWidth - 40;
      let zoom = width / 1000;
      this.profile_preview.nativeElement.style.transform ="scale(" + zoom + ")";
    });
  }

  img_search() {
    this.api.searchMedias(this.data.currentUser.customer.id, this.s).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
    })
  }

  addimage(m: Media) {
    this.profile.profile_imgs.push(m);
    this.preview();
  }

  removeImage(index) {
    this.profile.profile_imgs.splice(index, 1);
  }

  public onUploadInit(args: any): void {
    console.log('onUploadInit:', args);
  }

  public onUploadError(args: any): void {
    console.log('onUploadError:', args);
  }

  public onUploadSuccess(args: any): void {
    let id:number = +args[1];

    this.api.getMedia(id).subscribe((data: Media) => {
      this.profile.profile_imgs.push(data);
      this.DropZone.directiveRef.reset();
    })
  }
}
