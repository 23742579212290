import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

  heading = 'Statistics';
  subheading = '';
  icon = 'pe-7s-note icon-gradient bg-arielle-smile';

  public statdata: any;
  public loaded:boolean = false;

  constructor(public data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {

  }

  ngOnInit() {



  }




}

