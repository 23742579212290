import { Component, OnInit } from '@angular/core';
import { Recommend } from '../../../../../shared/models/recommend';
import { ApiService } from 'src/app/Services/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-recommends-map',
  templateUrl: './admin-recommends-map.component.html',
  styleUrls: ['./admin-recommends-map.component.scss']
})
export class AdminRecommendsMapComponent implements OnInit {
  heading = 'Alla hemesterplatser';
  subheading = 'Här redigerar du alla hemesterplatser';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public recommends: Recommend[] = [];
  public lat = 40.730610;
  public lng = -73.935242;

  constructor(private api: ApiService, private router: Router) { }

  ngOnInit() {

    this.api.getAllRecommends().subscribe((data: Recommend[]) => {
      this.recommends = data;
    })


  }

  open(id: number)
  {
    this.router.navigateByUrl('admin/recommend/' + id)
  }

}
