import { AdTag } from './adtag';
import { menuObject } from './menuObject';
import { Place } from './place';

export class App {

    public id!: number;
    public name!: String;
    public shortName!: String;
    public domain!: String;
    public support_email!: string;
    public menu!: String;
    public menu2!: String;
    public booli_query!: String;
    public station_str!: String;
    public police_url!: String;
    public workads_url!: String;
    public trafic_xml!: String;
    public firebase_key!: string;
    public workads_code!: string;
    public default_artwork!: string;
    public android_stream_url!: string;
    public ios_stream_url!: string;
    public android_share_url!: string;
    public ios_share_url!: string;
    public android_notification_color!: string;

    public keywords!: string;

    public places!: Place[];
    public newsapps!: App[];
    public url_scheme!: string;
    public itunes_link!: string;
    public googleplay_link!: string;

    public adTags!: AdTag[];

    public newsOptIn!: boolean;
    public css!: string;
    public menuObject2!: menuObject[];
    public tabMenu!: menuObject[];

}

