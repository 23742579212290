import { Component, OnInit } from '@angular/core';
import { Contact } from '../../../../../shared/models/contact';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '../../../../../shared/models/response';

@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.scss']
})
export class ContactEditComponent implements OnInit {

  heading = 'Redigera profilsidan';
  subheading = 'Här redigerar du din profilsida';
  icon = 'pe-7s-note icon-gradient bg-arielle-smile';

  public contact: Contact = new Contact();


  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {

      let id = +this.route.snapshot.paramMap.get("id");
      this.loadContent(id);

  }

  ngOnInit() {
    this.route.params.subscribe(routeParams => {
      this.loadContent(routeParams.id);
    });
  }

  private loadContent(id: number)
  {

    if (id != 0)
    {

      this.heading = 'Redigera kontakt';
      this.subheading = 'Här redigerar du ett kontakter som ska visas på profilsidan.';

      this.api.getContact(id).subscribe((data: Contact) => {
        this.contact = data;
      });

    }
    else
    {
      this.contact.id = 0;
    }

  }


  save()
  {
    this.api.setContact(this.contact).subscribe((data:Response) => {
      this.contact.id = data.id;

      this.api.showSuccess("Sparad", "Kontakten sparades");

    }, error => this.api.showError(error));
  }

  public delete()
  {
    if(confirm("Är du säker på att du vill ta bort den här kontakten?")) {

      this.api.delContact(this.contact.id).subscribe((data:any) => {
        this.router.navigateByUrl('/contacts');
      });
    }
  }

}
